import { createUseTranslation, createTrans, createWithTranslation, init as _init } from "@planckdata/i18n"
import i18n from "i18next"
import moment from "moment"
import "moment/locale/de"
import { ENV } from "env"

export const i18nNamespace = "pwa"
export const defaultLocale = "en-US"

export const useTranslation = createUseTranslation(i18nNamespace)
export const Trans = createTrans(i18nNamespace)
export const withTranslation = createWithTranslation(i18nNamespace)

export function initI18n(i18nNamespaces: Array<string>, languages: Array<string>): void {
  const timestamp = moment(Date.now()).format("yyyyMMDDHH")
  _init(i18n, {
    ns: i18nNamespaces,
    supportedLngs: languages,
    load: "currentOnly",
    backend: {
      loadPath: `/locales/{{ns}}/{{lng}}.json?v=${ENV.APP_VERSION ?? timestamp}`,
    },
  })
  i18n.on("languageChanged", (lng: string) => {
    moment.locale(lng)
  })
}

export { i18n }
