import React from "react"
import moment from "moment"
import { useParams } from "react-router"
import { CaseDataResult, ExtendedCaseData, Insight, UserAPI } from "@planckdata/api"
import { SeenEvidenceStore } from "../../common/SeenEvidenceStore"
import { useCaseFetch } from "../../../hooks/CaseFetch.hooks"
import { PWAUserSettings, useUser } from "user-context"
import { useLocalStorage } from "@planckdata/typescript-utils/storage_utils"
import { useWindowSize } from "@planckdata/react-components"
import StorageKeys, { StorageNS } from "storageKeys"
import { useInputHighlight } from "../../../components/organisms/OpenCaseForm/OpenCaseForm.hooks"
import insightNames from "../../../utils/insight-names"

export interface SingleBusinessPageHookResponse {
  loading: boolean
  anyCompleted: boolean
  noAnswerCodes: boolean
  data: ExtendedCaseData | undefined
  newEvidenceCount: number
  areInsightsLoading: boolean
  mainInsightLoading: boolean
  caseId: string
  onInsightFeedbackViewed(i: Insight): void
  updateCaseData<K extends keyof ExtendedCaseData>(key: K, value: React.SetStateAction<ExtendedCaseData[K]>): void
}

export interface SingleBusinessPageUIHookResponse {
  inputHighlight: ReturnType<typeof useInputHighlight>[0]
  setInputHighlight: ReturnType<typeof useInputHighlight>[1]
  advancedSearchOpen: boolean
  setAdvancedSearchOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export interface SingleBusinessPageHookParamTypes {
  caseId: string
}

export interface SingleBusinessPageOptions {
  sections: Array<keyof ExtendedCaseData>
}

const noop = () => void 0

export const SingleBusinessPageContext = React.createContext<SingleBusinessPageHookResponse>({
  loading: true,
  anyCompleted: false,
  noAnswerCodes: false,
  data: undefined,
  newEvidenceCount: 0,
  areInsightsLoading: true,
  mainInsightLoading: true,
  caseId: "",
  onInsightFeedbackViewed: noop,
  updateCaseData: noop,
})

export const SingleBusinessPageUIContext = React.createContext<SingleBusinessPageUIHookResponse>({
  inputHighlight: false,
  setInputHighlight: noop,
  advancedSearchOpen: false,
  setAdvancedSearchOpen: noop,
})

/**
 * Hook for accessing the data about SBP from the context (must be provided elsewhere)
 *
 * @see {@link useSingleBusinessPage} for loading the data
 */
export const useSingleBusinessPageContext = (): SingleBusinessPageHookResponse => {
  return React.useContext(SingleBusinessPageContext)
}

/**
 * Hook for accessing the data about SBP **UI** from the context (must be provided elsewhere)
 *
 * @see {@link useSingleBusinessPageUI} for creating the state
 */
export const useSingleBusinessPageUIContext = (): SingleBusinessPageUIHookResponse => {
  return React.useContext(SingleBusinessPageUIContext)
}

/**
 * Hook for loading the data for SBP. This actually performs the fetches and logic, so it should be used in the
 * top-level component only. For accessing the data, use {@link useSingleBusinessPageContext}
 * @param {SingleBusinessPageOptions} options The options for the hook
 * @returns {SingleBusinessPageHookResponse} The data for the SBP page
 */
export function useSingleBusinessPage({ sections }: SingleBusinessPageOptions): SingleBusinessPageHookResponse {
  const { caseId } = useParams<SingleBusinessPageHookParamTypes>()
  const { caseData, loading, updateCaseData } = useCaseFetch(caseId, sections)
  const [newEvidenceCount, setNewEvidenceCount] = React.useState(
    caseData.case ? SeenEvidenceStore.getNewEvidenceCount(caseData.case) : 0,
  )

  const areInsightsLoading =
    !caseData || !caseData.case || (!caseData.case.is_completed && caseData.case.insights.length === 0)
  const noAnswerCodes = caseData.case?.answer_codes.length === 0

  const noCaseData = !caseData || !caseData.case
  const mainAddressInsightExists = Boolean(
    caseData?.case?.insights.find(({ name }) => name === insightNames.main_address),
  )
  const mainInsightLoading = noCaseData || (!mainAddressInsightExists && !caseData.case?.is_completed)

  function onInsightFeedbackViewed(insight: Insight) {
    if (insight.evidence && !SeenEvidenceStore.getEvidenceSeen(caseId, insight.name)) {
      SeenEvidenceStore.setEvidenceSeen(caseId, insight.name)
      setNewEvidenceCount(newEvidenceCount - 1)
    }
  }

  const anyCompleted = sections.map((key) => caseData[key]).filter((p) => !!p).length > 0

  return {
    anyCompleted,
    loading,
    areInsightsLoading,
    mainInsightLoading,
    data: caseData,
    caseId,
    newEvidenceCount,
    noAnswerCodes,
    onInsightFeedbackViewed,
    updateCaseData,
  }
}

export function useSingleBusinessPageUI(): SingleBusinessPageUIHookResponse {
  const [inputHighlight, setInputHighlight] = useInputHighlight()
  const [advancedSearchOpen, setAdvancedSearchOpen] = React.useState(false)

  return {
    inputHighlight,
    setInputHighlight,
    advancedSearchOpen,
    setAdvancedSearchOpen,
  }
}

//
//
// TODO deprecate old onbaording - remove anything below here
//
//

export interface VersionHookData<T extends boolean> {
  isNewVersion: boolean
  setNewVersion: (value: boolean) => Promise<void>
  showRevertControl: boolean
  onboarding: T extends true
    ? {
        needsConfetti: boolean
        removeNeedsConfetti: () => void
      }
    : undefined
}

export function useSingleBusinessVersion<T extends boolean>({
  withOnboarding = false as T,
}: { withOnboarding?: T } = {}): VersionHookData<T> {
  const user = useUser().current
  const confettiFlag = useLocalStorage<boolean>(StorageNS.CONFETTI, StorageKeys.SBP_V2_SHOW_CONFETTI)

  const localVersionFlag = useLocalStorage<boolean | undefined>("flags", StorageKeys.SBP_V2_FLAG_OVERRIDE)
  const localVersionFlagValue = localVersionFlag.get()
  const localVersionFlagValueExists = localVersionFlagValue !== undefined

  // Version from user settings - old - only used as fallback
  const userOldVersionFlagValue = Boolean(user?.flags.newSingleBusinessPage)
  const userOldVersionFlagValueExists = user?.flags.newSingleBusinessPage !== undefined
  const userNewVersionSettingValue = user?.userSettings.ShowSBPV1
  const userNewVersionSettingValueExists = userNewVersionSettingValue !== undefined
  const showToggleFlag = Boolean(userNewVersionSettingValue || user?.userSettings.ShowSBPV1Toggle)

  const usesSBPv2 = React.useMemo(
    (): boolean => (userNewVersionSettingValueExists ? !userNewVersionSettingValue! : true),
    [userNewVersionSettingValue, userNewVersionSettingValueExists],
  )
  const [needsConfetti, setNeedsConfetti] = React.useState(confettiFlag.get())

  React.useEffect(() => {
    async function doVersionLogic() {
      if (!userNewVersionSettingValueExists && !userOldVersionFlagValueExists && localVersionFlagValueExists) {
        await UserAPI.updateSettings<PWAUserSettings>({
          ShowSBPV1: !localVersionFlagValue,
          ShowSBPV1Toggle: !localVersionFlagValue,
        })
        localVersionFlag.remove()
      }
    }

    doVersionLogic()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onboarding: VersionHookData<true>["onboarding"] = React.useMemo(
    () => ({
      needsConfetti: needsConfetti == null ? true : needsConfetti,
      removeNeedsConfetti: () => {
        confettiFlag.save(false)
        setNeedsConfetti(false)
      },
    }),
    [confettiFlag, needsConfetti],
  )

  const setNewVersion = React.useCallback(
    async (value: boolean) => {
      localVersionFlag.remove()
      if (value && withOnboarding) {
        confettiFlag.save(true)
      }
      const update: PWAUserSettings = {
        ShowSBPV1: !value,
        ShowSBPV1Toggle:
          (userOldVersionFlagValueExists && !userOldVersionFlagValue) ||
          (localVersionFlagValueExists && !localVersionFlagValue) ||
          (userNewVersionSettingValueExists && userNewVersionSettingValue),
      }
      if (user?.userSettings.ShowSBPV1Toggle !== undefined) {
        delete update.ShowSBPV1Toggle
      }
      await UserAPI.updateSettings<PWAUserSettings>(update)
    },
    [
      confettiFlag,
      localVersionFlag,
      localVersionFlagValue,
      localVersionFlagValueExists,
      user?.userSettings.ShowSBPV1Toggle,
      userNewVersionSettingValue,
      userNewVersionSettingValueExists,
      userOldVersionFlagValue,
      userOldVersionFlagValueExists,
      withOnboarding,
    ],
  )
  const data = React.useMemo((): VersionHookData<T> => {
    return {
      isNewVersion: usesSBPv2,
      setNewVersion: setNewVersion,
      showRevertControl: showToggleFlag,
      onboarding: withOnboarding ? onboarding : (undefined as any),
    }
  }, [onboarding, setNewVersion, showToggleFlag, usesSBPv2, withOnboarding])

  return data
}

export function useCopyAndSearchListeners({
  onCopy,
  onSearch,
}: {
  onCopy: (selection: Selection | null) => void
  onSearch: () => void
}): void {
  const handleKeydown = React.useCallback(
    (e: KeyboardEvent) => {
      // TODO: Move this to @planckdata/typescript-utils
      const platform = (navigator as any)?.userAgentData?.platform || navigator?.platform || "unknown"
      const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(platform)

      if (e.key === "F3" || (!isMacLike && e.ctrlKey && e.key === "f") || (isMacLike && e.metaKey && e.key === "f")) {
        onSearch()
      }
    },
    [onSearch],
  )

  const handleCopy = React.useCallback(() => {
    const selection = document.getSelection()
    onCopy(selection)
  }, [onCopy])

  React.useEffect(() => {
    const copyEvent = "copy"
    const keyDownEvent = "keydown"

    window.addEventListener(copyEvent, handleCopy)
    window.addEventListener(keyDownEvent, handleKeydown)

    return () => {
      window.removeEventListener(copyEvent, handleCopy)
      window.removeEventListener(keyDownEvent, handleKeydown)
    }
  }, [handleCopy, handleKeydown])
}

export function useOnboarding({
  caseData,
  confettiDuration,
  confettiFadeDuration,
}: {
  caseData: CaseDataResult | null
  confettiDuration: number
  confettiFadeDuration: number
}): {
  showConfetti: boolean
  confettiVisible: boolean
} {
  const { onboarding } = useSingleBusinessVersion({ withOnboarding: true })
  const windowSize = useWindowSize()
  const [confettiVisible, setConfettiVisible] = React.useState(true)

  const showConfetti = React.useMemo(
    () => Boolean(caseData?.is_completed && onboarding.needsConfetti && windowSize.width && windowSize.height),
    [caseData?.is_completed, onboarding.needsConfetti, windowSize.width, windowSize.height],
  )

  React.useEffect(() => {
    if (showConfetti) {
      const fadeTimeout = setTimeout(() => {
        setConfettiVisible(false)
      }, confettiDuration)

      const removeTimeout = setTimeout(() => {
        onboarding.removeNeedsConfetti()
      }, confettiDuration + confettiFadeDuration)

      return () => {
        clearTimeout(removeTimeout)
        clearTimeout(fadeTimeout)
      }
    }
  }, [confettiDuration, confettiFadeDuration, onboarding, showConfetti])

  return { showConfetti, confettiVisible }
}

export interface BusinessesOpenedHookData {
  businessesOpened: number
  setBusinessesOpened: (newValue: number) => void
}

export function useSingleBusinessBusinessesOpened(): BusinessesOpenedHookData {
  const businessesOpened = useLocalStorage<number>(StorageNS.PWA, StorageKeys.SBP_TOGGLE_CASE_COUNT)

  return {
    businessesOpened: businessesOpened.get() ?? 0,
    setBusinessesOpened: (newValue: number) =>
      businessesOpened.save(newValue, { expires: moment().add(1, "day").toDate() }),
  }
}
