import React from "react"
import { useTranslation } from "i18n"
import { CheckIcon, ProcessingIcon, ErrorIcon } from "@planckdata/react-components/components/icons"
import { IconLevel, TextIconContainer } from "../../atoms/TextIconContainer"
import { makeStyles, darken, alpha, GridSpacing, colors } from "@planckdata/react-components"
import cls from "clsx"
import { TextIconProps } from "./BasicIcons"
import { CaseStatus } from "@planckdata/api/case/models/case-status"
import { CaseData, CaseRun } from "@planckdata/api/case/models"

const useStyles = makeStyles(() => ({
  iconContainer: {
    verticalAlign: "middle",
    borderRadius: "50%",
    width: 16,
    height: 16,
  },
  completedIconContainer: {
    color: colors.success,
    background: alpha(colors.success, 0.2),
  },
  processingIconContainer: {
    color: darken(colors.warning, 0.2),
    background: alpha(colors.warning, 0.4),
    "& circle": {
      fillOpacity: 0,
    },
  },
  errorIconContainer: {
    color: darken(colors.error, 0.2),
    background: alpha(colors.error, 0.4),
    "& circle, & rect": {
      fillOpacity: 0,
    },
  },
  disabledIconContainer: {
    color: `${colors.foregroundAltText}`,
  },
  greyIcon: {
    filter: "grayscale(100%)",
  },
}))

//Todo: implement all statuses in the same way
export const CompletedTextIcon: React.FC<TextIconProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <TextIconContainer text={t("text_icons.status.completed")} {...props}>
      <CheckIcon className={cls(classes.iconContainer, classes.completedIconContainer)} />
    </TextIconContainer>
  )
}

export const ProcessingTextIcon: React.FC<TextIconProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <TextIconContainer text={t("text_icons.status.processing")} level={IconLevel.Warning} {...props}>
      <ProcessingIcon className={cls(classes.iconContainer, classes.processingIconContainer)} />
    </TextIconContainer>
  )
}

export const ErrorTextIcon: React.FC<TextIconProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <TextIconContainer text={t("text_icons.status.error")} level={IconLevel.Error} {...props}>
      <ErrorIcon className={cls(classes.iconContainer, classes.errorIconContainer)} />
    </TextIconContainer>
  )
}

export const CaseStatusIconText: React.FunctionComponent<
  { case: CaseData | CaseRun; spacing?: GridSpacing } & TextIconProps
> = ({ case: caseData, spacing = 1, ...props }) => {
  const { t } = useTranslation()
  const status = isCaseRun(caseData) ? caseData.status : getCaseDataStatus(caseData)

  if (status === CaseStatus.ERROR) {
    return (
      <ErrorTextIcon text={t("text_icons.status.case_error")} spacing={spacing} data-testid="ErrorStatus" {...props} />
    )
  }

  if (status === CaseStatus.COMPLETED || status === CaseStatus.FEEDBACK_PROVIDED) {
    return <CompletedTextIcon spacing={spacing} data-testid="CompletedStatus" {...props} />
  }

  return <ProcessingTextIcon spacing={spacing} data-testid="ProcessingStatus" {...props} />
}

function getCaseDataStatus(caseData: CaseData) {
  if (caseData.error) {
    return CaseStatus.ERROR
  }

  if (caseData.is_completed) {
    return CaseStatus.COMPLETED
  }

  return CaseStatus.PROCESSING
}

function isCaseRun(obj: any): obj is CaseRun {
  return "status" in obj
}
