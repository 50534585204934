import React from "react"
import {
  ExportIcon,
  FolderIcon,
  EnvelopeClosedIcon,
  // DashboardIcon,
  NewBadgeIcon,
  DocumentIcon,
} from "@planckdata/react-components/components/icons"
import { SideMenuItemProps } from "./SideMenuItem"
import { GenerateInsightsBasePath, Routes } from "routes"
import { excludeIncludeValues } from "@planckdata/react-components"
import { ShinyIcon } from "components"
import InsightsMappingTourWrapper from "./InsightsMappingTourWrapper"
import { AskMaxMenuIcon } from "components/atoms/AskMax/AskMaxMenuIcon"
import { isAuthorizedOrHasFlag, Permission } from "user-context"
const intlPrefix = (s: string) => `side_menu.${s}`

export const MenuItems: Omit<SideMenuItemProps, "sideMenuOpen">[] = [
  {
    icon: <ExportIcon />,
    text: intlPrefix("generate_insights"),
    route: GenerateInsightsBasePath,
    allowMatch: (path) => path.startsWith(GenerateInsightsBasePath) || path === "/",
    items: [
      {
        text: intlPrefix("single_business"),
        route: Routes.SingleBusiness,
        allowMatch: (path) => path === Routes.Home || path === GenerateInsightsBasePath,
      },
      {
        text: intlPrefix("list_of_businesses"),
        route: Routes.Batch,
        allowMatch: (path) => path.startsWith("/generate-insights/batch"),
      },
    ],
  },
  {
    icon: <FolderIcon />,
    text: intlPrefix("my_businesses"),
    route: Routes.MyBusinesses,
    // items: [
    //   {
    //     text: prefix("completed"),
    //     route: Routes.MyBusinesses,
    //   },
    //   {
    //     text: prefix("waiting_for_feedback"),
    //     route: Routes.MyBusinessesWaiting,
    //   },
    // ],
  },
  // {
  //   icon: <DashboardIcon />,
  //   text: intlPrefix("dashboard"),
  //   route: Routes.Dashboard,
  //   visible: (user) => isAuthorizedOrHasFlag(user, Permission.ViewPocDashboard),
  // },
  {
    icon: <InsightsMappingTourWrapper render={() => <DocumentIcon />} />,
    text: intlPrefix("insights_mapping"),
    route: Routes.InsightsMapping,
    visible: (user) =>
      excludeIncludeValues({
        values: user?.permissions,
        includeFor: ["ShowInsightsMapping"],
      }),
  },
  {
    icon: <EnvelopeClosedIcon />,
    text: intlPrefix("support"),
    route: Routes.Support,
  },
  {
    icon: <AskMaxMenuIcon />,
    text: intlPrefix("ask_max"),
    route: Routes.AskMax,
    visible: (user) => isAuthorizedOrHasFlag(user, Permission.AskMax),
  },
]
