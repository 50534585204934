import React from "react"
import { makeStyles, colors, ButtonBaseProps, ButtonBase } from "@planckdata/react-components"
import cls from "clsx"

const useStyles = makeStyles({
  root: {
    borderRadius: 15,
    padding: "8px 16px",
    color: colors.foregroundText,
    fontSize: 16,
  },
})

export const FeedbackButton: React.FC<ButtonBaseProps> = ({ className, ...props }) => {
  const classes = useStyles()

  return <ButtonBase className={cls(className, classes.root)} {...props} />
}
