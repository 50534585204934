import React, { lazy } from "react"
import { Route, Switch } from "react-router-dom"
import clsx from "clsx"
import { batchRoutes, GenerateInsightsBasePath, MyBusinessesRoutes, Routes } from "routes"
import MainLayout, { CONTENT_MAX_WIDTH } from "components/layouts/MainLayout"
import PrivateRoute from "./PrivateRoute"
import { Button, excludeIncludeValues, makeStyles, PageContentBox, Theme } from "@planckdata/react-components"
import { useTranslation } from "i18n"
import AppLoader from "../AppLoader"
import { isAuthorized, isAuthorizedOrHasFlag, Permission, useUser } from "user-context"
import { useHistory, useLocation, useParams } from "react-router"
import { UserSettingSwitch } from "components/atoms/FeatureFlagSwitch"
import { useLocalStorage } from "@planckdata/typescript-utils/storage_utils"
import { dumpQueryString } from "@planckdata/typescript-utils/url_utils"
import StorageKeys, { StorageNS } from "storageKeys"
import { SBPContextProvider } from "pages/SingleBusiness/v2/SBPOnboardingContext"
import { Error404Page } from "../../../components/molecules/ErrorComponents"
import { Error428Page } from "../../../components/molecules/ErrorComponents/Error428"

export interface PwaRoutesProps {}

const useStyles = makeStyles<Theme, { contentWidth?: number; maxWidth?: number }>((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(4),
    width: "100%",
    maxWidth: ({ maxWidth }) => maxWidth,
  },
  limitWidth: {
    width: ({ contentWidth }) => contentWidth,
  },
}))

const MainLayoutRoutes: React.FC<PwaRoutesProps> = () => {
  const classes = useStyles({ contentWidth: 1024, maxWidth: CONTENT_MAX_WIDTH })
  const { t } = useTranslation()
  const { current } = useUser()

  const OpenSingleBusinessPage = lazy(() => import("pages/OpenSingleBusiness"))
  // const DashboardPage = lazy(() => import("pages/Dashboard"))
  const UploadBatchProcessPage = lazy(() => import("pages/UploadBatchProcess"))
  const MyBusinessesPage = lazy(() => import("pages/MyBusinesses"))
  const DownloadBatchPage = lazy(() => import("pages/DownloadBatch"))
  const InsightsMapping = lazy(() => import("pages/InsightsMapping"))
  const AskMax = lazy(() => import("pages/AskMaxPage/AskMaxPage"))
  const SupportPage = lazy(() => import("pages/SupportPage"))
  const devSkipInternalCheck = useLocalStorage<boolean>(StorageNS.DEV, StorageKeys.DEV_SKIP_INTERNAL_CHECK)
  const isInternal = React.useMemo(
    () => (devSkipInternalCheck.get() ? false : Boolean(current?.internal)),
    [current?.internal, devSkipInternalCheck],
  )

  const isPilotUser = Boolean(current?.isPilot)
  const noInsightsMapped = !current?.hasMappedInsights

  const pilotGenerateInsightsDisableTooltipText = isPilotUser
    ? t("generate_insights.disabled_pilot_tooltip")
    : undefined

  const history = useHistory()
  const location = useLocation()

  React.useEffect(() => {
    if (
      current &&
      !isInternal &&
      current.userSettings?.termsAndConditions &&
      !current.userSettings?.termsAndConditions.lastApprovedDate &&
      !location.pathname.startsWith(Routes.TermsAndConditions)
    ) {
      history.replace(Routes.TermsAndConditions + dumpQueryString({ returnTo: location.pathname + location.search }))
    } else if (isPilotUser && location.pathname === Routes.Home) {
      history.push(Routes.MyBusinesses)
    }
  }, [history, isPilotUser, location, current, devSkipInternalCheck, isInternal])

  return (
    <MainLayout ns="pwa">
      <React.Suspense fallback={<AppLoader />}>
        <Switch>
          {/* <PrivateRoute
            path={Routes.Dashboard}
            permissionCheck={(user) => isAuthorizedOrHasFlag(user, Permission.ViewPocDashboard)}
            render={() => (
              <div className={classes.pageContainer}>
                <PageContentBox style={{ width: "100%" }} padding={30}>
                  <DashboardPage />
                </PageContentBox>
              </div>
            )}
          /> */}
          <PrivateRoute
            path={Routes.Batch}
            render={() => (
              <div className={clsx(classes.pageContainer, classes.limitWidth)}>
                <PageContentBox>
                  <UploadBatchProcessPage
                    routes={batchRoutes}
                    disabled={
                      isPilotUser ||
                      !isAuthorized(current, Permission.OpenBatch) ||
                      Boolean(current?.flags.disableBatchUpload)
                    }
                    uploadBatchTooltipText={pilotGenerateInsightsDisableTooltipText}
                  />
                </PageContentBox>
              </div>
            )}
          />
          <PrivateRoute path={Routes.SingleBusinessPage} render={() => <SingleBusinessPageRouter />} />
          <PrivateRoute
            path={Routes.MyBusinesses}
            render={() => (
              <div className={classes.pageContainer}>
                <MyBusinessesPage
                  routes={MyBusinessesRoutes}
                  businessesScrollContainerProps={{ height: "calc(100vh - 300px)" }}
                />
              </div>
            )}
          />
          <PrivateRoute
            path={Routes.DownloadExportedFile + "/:fileName"}
            render={() => (
              <div className={classes.pageContainer}>
                <DownloadBatchPage />
                <MyBusinessesPage
                  routes={MyBusinessesRoutes}
                  businessesScrollContainerProps={{ height: "calc(100vh - 300px)" }}
                />
              </div>
            )}
          />
          {[Routes.SingleBusiness, Routes.Home, GenerateInsightsBasePath].map((path, index) => (
            <PrivateRoute
              key={index}
              path={path}
              exact
              render={() =>
                noInsightsMapped ? (
                  <Error428Page />
                ) : (
                  <div className={clsx(classes.pageContainer, classes.limitWidth)}>
                    <PageContentBox>
                      <OpenSingleBusinessPage
                        inputDisabled={isPilotUser}
                        addressTooltipText={pilotGenerateInsightsDisableTooltipText}
                        businessNameTooltipText={pilotGenerateInsightsDisableTooltipText}
                      />
                    </PageContentBox>
                  </div>
                )
              }
            />
          ))}
          <PrivateRoute
            path={Routes.InsightsMapping}
            permissionCheck={(user) =>
              excludeIncludeValues({
                values: user?.permissions,
                includeFor: ["ShowInsightsMapping"],
              })
            }
            render={() => (
              <div className={classes.pageContainer}>
                <InsightsMapping />
              </div>
            )}
          />
          <PrivateRoute
            path={Routes.Support}
            exact
            render={() => (
              <div className={clsx(classes.pageContainer)} style={{ width: 1200 }}>
                <PageContentBox width={1200}>
                  <SupportPage />
                </PageContentBox>
              </div>
            )}
          />
          <PrivateRoute
            path={Routes.AskMax}
            exact
            permissionCheck={(user) => isAuthorizedOrHasFlag(user, Permission.AskMax)}
            render={() => (
              <div className={clsx(classes.pageContainer, classes.limitWidth)}>
                <PageContentBox>
                  <AskMax />
                </PageContentBox>
              </div>
            )}
          />

          <Route
            path="*"
            render={() => (
              <Error404Page
                cta={({ ctx }) => (
                  <Button href={Routes.Home} onClick={ctx.clearError}>
                    {t("error_pages.404.cta")}
                  </Button>
                )}
              />
            )}
          />
        </Switch>
      </React.Suspense>
    </MainLayout>
  )
}

const SingleBusinessPageRouter: React.FC<any> = (props) => {
  const classes = useStyles(props)
  const { current } = useUser()
  const flag = useLocalStorage<boolean>(StorageNS.FLAGS, StorageKeys.SBP_V2_FLAG_OVERRIDE)
  const { caseId } = useParams<{ caseId: string }>()

  const SingleBusinessPage = lazy(() => import("pages/SingleBusiness/v1"))

  React.useEffect(() => {
    if (current?.flags.hideSBPRolloutPopup) {
      flag.remove()
    }
  }, [current, flag])

  return (
    <UserSettingSwitch
      key={caseId}
      setting="ShowSBPV1"
      renderActive={() => (
        <div className={classes.pageContainer}>
          <SingleBusinessPage />
        </div>
      )}
      renderInactive={() => <SingleBusinessPageContextWrapper />}
    />
  )
}

const SingleBusinessPageContextWrapper: React.FC = () => {
  const SingleBusinessPageV2 = lazy(() => import("pages/SingleBusiness/v2"))

  return (
    <div style={{ paddingTop: 8 }}>
      <SBPContextProvider>
        <SingleBusinessPageV2 />
      </SBPContextProvider>
    </div>
  )
}

export default MainLayoutRoutes
