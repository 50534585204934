import React from "react"
import { CustomComponentProps } from "@planckdata/react-components"
import Image from "./images/error-404.svg"
import { CustomErrorDialog, CustomErrorDialogProps, CustomErrorPage, CustomErrorPageProps } from "./CustomError"

export interface Error404PageProps extends CustomComponentProps<Partial<CustomErrorPageProps>> {
  //
}

export interface Error404DialogProps extends CustomComponentProps<Partial<Omit<CustomErrorDialogProps, "open">>> {
  open: boolean
}

/** @see {@link CustomErrorPage} for props you may override */
export const Error404Page: React.FC<Error404PageProps> = (props) => {
  const { className, ...rest } = props

  return (
    <CustomErrorPage
      className={className}
      title="error_pages.404.title"
      description="error_pages.404.description"
      image={Image}
      {...rest}
    />
  )
}

/** @see {@link CustomErrorDialog} for props you may override */
export const Error404Dialog: React.FC<Error404DialogProps> = (props) => {
  const { className, ...rest } = props

  return (
    <CustomErrorDialog
      className={className}
      title="error_pages.404.title"
      description="error_pages.404.description"
      {...rest}
    />
  )
}
