import React from "react"
import {
  Logo,
  colors,
  CustomComponentProps,
  Grid,
  GridProps,
  makeStyles,
  PageContentBox,
  typography,
  Typography,
} from "@planckdata/react-components"

import cls from "clsx"

export interface FeedbackChatItemProps extends CustomComponentProps<Partial<GridProps>> {
  createdBy?: string | null
  createdAt?: string | null
  text?: string | null
  type: "feedback" | "evidence"
}

const useStyles = makeStyles({
  Circle: {
    borderRadius: "50%",
    textAlign: "center",
    margin: "auto",
    ...typography({ size: 20, weight: 600 }),
    width: 40,
    height: 40,
    background: colors.primary,
    color: colors.contrastText,
  },
  CreatedBy: {
    color: colors.basicBackgroundColor,
    float: "left",
    marginRight: "0.5ch",
  },
  CreatedAt: {
    color: colors.disable,
    float: "left",
    maxWidth: "100%",
  },
  Text: {
    display: "inline-block",
    width: "100%",
    padding: 10,
  },
  FeedbackColor: {
    backgroundColor: colors.menuItemHighlight,
    color: colors.foregroundText,
  },
  EvidenceColor: {
    backgroundColor: colors.primary,
    color: colors.contrastText,
  },
})

export const FeedbackChatItem: React.FC<FeedbackChatItemProps> = ({ createdBy, createdAt, text, type, ...props }) => {
  const classes = useStyles(props)

  function UserCircle() {
    return (
      <div className={classes.Circle}>
        {" "}
        {type === "feedback" ? (
          createdBy?.charAt(0).toUpperCase()
        ) : (
          <Logo variant="mini" color={colors.contrastText} style={{ marginBottom: 1 }} />
        )}
      </div>
    )
  }

  return (
    <Grid container {...props}>
      <Grid item xs={2} style={{ alignSelf: "flex-end" }} hidden={type == "evidence"}>
        <UserCircle />
      </Grid>
      <Grid item xs={10}>
        <Grid container justifyContent={type == "evidence" ? "flex-end" : "flex-start"}>
          <Grid item style={{ paddingBottom: 3 }}>
            <Grid container>
              <Grid item>
                <Typography
                  size={14}
                  weight={600}
                  className={classes.CreatedBy}
                  component="span"
                  noWrap
                  hidden={!createdBy}
                >
                  {createdBy},
                </Typography>
                <Typography size={14} className={classes.CreatedAt} component="span" noWrap>
                  {createdAt}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent={type == "evidence" ? "flex-end" : "flex-start"}>
              <Grid item>
                <PageContentBox
                  padding={0}
                  className={cls(classes.Text, type == "feedback" ? classes.FeedbackColor : classes.EvidenceColor)}
                >
                  <Typography size={16} weight={400} component="span" style={{ wordBreak: "break-word" }}>
                    {text}
                  </Typography>
                </PageContentBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} style={{ alignSelf: "flex-end" }} hidden={type == "feedback"}>
        <UserCircle />
      </Grid>
    </Grid>
  )
}
