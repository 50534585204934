export type AnswerCodeNames = keyof typeof AnswerCodeNames

/**
 * A mapping of all answer codes and their names for type inference.
 * Add new answer codes here to make them available to the rest of the app.
 */
export const AnswerCodeNames = {
  partial_address: "partial_address",
  permanently_closed: "permanently_closed",
  mailing_address: "mailing_address",
  mismatch_requested_segment: "mismatch_requested_segment",
  different_address_matched: "different_address_matched",
  dba_is_legal_name: "dba_is_legal_name",
  low_web_presence: "low_web_presence",
  no_web_presence: "no_web_presence",
  high_web_presence: "high_web_presence",
  property_only: "property_only",
  lob_only: "lob_only",
} as const
