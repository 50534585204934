import { UserInput } from "@planckdata/api/case/models/user-input"
import { ENV } from "env"
import React from "react"
import { useUser } from "user-context"
import InsightName from "utils/insight-names"
import { GridKeys } from "./BusinessDataGrid"
import { useCaseData } from "./CaseDataContext"
import { AnswerCode } from "@planckdata/api/case/models/answer-code"
import { AnswerCodeNames } from "utils/AnswerCodeNames"

export interface SingleBusinessGrid {
  areas: Array<Array<GridKeys>>
  visibleAreas: Array<GridKeys>
  gridTemplateAreas: string
  gridTemplateColumns: string
  columnCount: number
  answerCodes: AnswerCode[]
}

export function useSingleBusinessPageGrid(): SingleBusinessGrid {
  const { value } = useCaseData()
  const answerCodes = React.useMemo(() => value?.case?.answer_codes || [], [value?.case?.answer_codes])
  const { current: user } = useUser()
  const hideGovtSources = true
  const hideNAICS = user?.flags.hideSBPNaics
  const isLobAnswerCode: boolean = React.useMemo(() => isLobOnly(answerCodes), [answerCodes])

  const areas: GridKeys[][] = React.useMemo(() => {
    if (isLobAnswerCode) {
      return [["basic_info", "nearby"]]
    }
    if (hideGovtSources) {
      return [
        ["basic_info", "nearby"],
        [!hideNAICS ? "naics" : "basic_info", "nearby"],
      ]
    }
    return [
      ["basic_info", "nearby", !hideNAICS ? "naics" : "govt_sources"],
      ["basic_info", "nearby", "govt_sources"],
    ]
  }, [hideGovtSources, hideNAICS, isLobAnswerCode])

  const visibleAreas = React.useMemo(() => Array.from(new Set(areas.flat())), [areas])
  const columnCount = React.useMemo(() => areas.reduce((max, cur) => (cur.length > max ? cur.length : max), 0), [areas])
  const gridTemplateColumns = React.useMemo(
    () =>
      columnCount === 2
        ? isLobAnswerCode || visibleAreas.includes("govt_sources")
          ? `repeat(2, 1fr)`
          : `minmax(300px, 2fr) minmax(346px, 484px)`
        : `repeat(${columnCount - 1}, minmax(300px, 396px)) minmax(200px, auto)`,
    [columnCount, isLobAnswerCode, visibleAreas],
  )
  const gridTemplateAreas = React.useMemo(() => areas.map((a) => `"${a.join(" ")}"`).join("\n"), [areas])

  return {
    areas,
    visibleAreas,
    columnCount,
    gridTemplateColumns,
    gridTemplateAreas,
    answerCodes,
  }
}

export type SagaTab = "all" | "map" | "images" | "reviews" | "governmentalSources"

export function getSagaBusinessPageUrl(
  user_input: UserInput | undefined,
  caseId: string,
  page: SagaTab = "all",
): string {
  const address = user_input?.address.description || ""
  const name = user_input?.name || ""
  const qs = new URLSearchParams({ p: page, name, address, searchId: caseId })
  return `${ENV.SAGA_API}/search?${qs.toString()}`
}

export const SEPARATOR = "__SEPARATOR__"

export const businessInfoInsightNames: InsightName[] = [
  InsightName.doing_business_as,
  InsightName.legal_name,
  InsightName.main_address,
  InsightName.phone,
  InsightName.main_website,
  InsightName.email_address,
  InsightName.in_business_since,
  // SEPARATOR,
  InsightName.annual_revenue,
  InsightName.total_employee_count,
]

const businessInfoPropertyOnlyInsightNames: InsightName[] = [
  InsightName.main_address,
  InsightName.premise_coordinates,
  InsightName.building_footprint_sqft,
  InsightName.year_built,
  InsightName.effective_year_built,
]

/**
 * These insights should still be polled in the background after is_completed is true, usually because they are slow
 */
export const delayedInsightNames: InsightName[] = [InsightName.business_keywords, InsightName.business_description_gpt]

export const highlightedInsightNames: string[] = [
  // InsightName.ANNUAL_REVENUE, InsightName.TOTAL_EMPLOYEE_COUNT
]

export function getVisibleInsights(isPropertyOnly: boolean): InsightName[] {
  return isPropertyOnly ? businessInfoPropertyOnlyInsightNames : businessInfoInsightNames
}

export function isLobOnly(answerCodes: AnswerCode[] | undefined): boolean {
  return answerCodes?.find((i) => i.code === AnswerCodeNames.lob_only) != null
}
