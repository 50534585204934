import React from "react"
import { components, TourProvider, StepType, ProviderProps } from "@reactour/tour"
import { NavigationProps } from "@reactour/tour/dist/components/Navigation"
import { alpha, colors, makeStyles, Button, CustomComponentProps } from "@planckdata/react-components"
import { useTranslation } from "i18n"

export interface TourProps extends CustomComponentProps<Partial<ProviderProps>, keyof typeof useStyles> {
  steps: StepType[]
  prevStepLabel?: React.ReactNode
  nextStepLabel?: React.ReactNode
  doneLabel?: React.ReactNode
  onStep?: (step: number) => void
  onComplete?: () => void
}

const useStyles = makeStyles(() => ({
  tourButton: {
    paddingInline: 10,
  },
}))

const tourStyles: any = {
  popover: (base: any) => ({
    ...base,
    borderRadius: "8px",
    minWidth: "550px",
    boxShadow: `0px 10px 24px -2px ${alpha(colors.foregroundText, 0.2)}`,
  }),
  maskWrapper: (base: any) => ({ ...base, color: "white", filter: "blur(16px)" }),
  badge: (base: any) => ({
    ...base,
    left: "auto",
    right: "18px",
    top: "32px",
    background: "transparent",
    color: "black",
    boxShadow: "none",
  }),
  // Temporary fix until reactour fixes hideDots: https://github.com/elrumordelaluz/reactour/pull/456
  dot: () => ({
    display: "none",
  }),
}

const TourNavigation = (props: NavigationProps) => {
  return <components.Navigation {...props} hideDots={true}></components.Navigation>
}

// TODO: Fix blur
// TODO: Move to react-components

export const Tour: React.FC<TourProps> = (props) => {
  const { steps, onStep, onComplete, children, nextStepLabel, doneLabel, prevStepLabel, ...rest } = props
  const classes = useStyles(props)
  const { t } = useTranslation()

  return (
    <TourProvider
      styles={tourStyles}
      disableKeyboardNavigation={["esc"]}
      disableInteraction={true}
      steps={steps}
      badgeContent={({ totalSteps, currentStep }) => `${currentStep + 1}/${totalSteps}`}
      afterOpen={() => {
        document.body.style.overflowY = "hidden"
        onStep?.(0)
      }}
      beforeClose={() => {
        document.body.style.overflowY = "auto"
        onComplete?.()
      }}
      showCloseButton={false}
      onClickMask={() => null}
      components={{ Navigation: TourNavigation }}
      nextButton={({ currentStep, stepsLength, setIsOpen, setCurrentStep }) => {
        const isLastStep = currentStep === stepsLength - 1

        return (
          <Button
            className={classes.tourButton}
            data-testid="TourNextButton"
            onClick={() => {
              if (isLastStep) {
                setIsOpen(false)
              } else {
                setCurrentStep((s) => {
                  const step = s === stepsLength - 1 ? 0 : s + 1

                  onStep?.(step)
                  return step
                })
              }
            }}
          >
            {isLastStep
              ? doneLabel ?? t("single_business_page.tour.buttons.done")
              : nextStepLabel ?? t("single_business_page.tour.buttons.next")}
          </Button>
        )
      }}
      prevButton={({ currentStep, setCurrentStep }) => {
        const first = currentStep === 0

        return first ? (
          <div />
        ) : (
          <Button
            className={classes.tourButton}
            data-testid="TourPrevButton"
            onClick={() => {
              setCurrentStep((s) => {
                const step = s - 1

                onStep?.(step)
                return step
              })
            }}
          >
            {prevStepLabel ?? t("single_business_page.tour.buttons.previous")}
          </Button>
        )
      }}
      {...rest}
    >
      {children}
    </TourProvider>
  )
}

export default Tour
