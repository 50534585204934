import { Redirect, Route, RouteProps, useLocation } from "react-router"
import React from "react"
import { observer } from "mobx-react-lite"
import { LoadingContainer } from "@planckdata/react-components"
import AppLoader from "../AppLoader"
import { PWAUser, useUser } from "user-context"
import { Routes } from "routes"

export interface PrivateRouteProps extends RouteProps {
  permissionCheck?(user: PWAUser): boolean
  permissionFailRedirectTo?: string
}

export const PrivateRoute: React.FC<PrivateRouteProps> = observer(
  ({ permissionCheck, permissionFailRedirectTo = Routes.Home, ...props }) => {
    const user = useUser()
    const condition = permissionCheck ? user.current && permissionCheck(user.current!) : true
    const location = useLocation()

    const routeComponent = React.useMemo(() => {
      if (user.isLoggedIn) {
        if (condition) {
          return <Route {...props} />
        } else {
          return <Redirect to={{ pathname: permissionFailRedirectTo }} />
        }
      } else {
        const queryString = `returnTo=${encodeURIComponent(location.pathname + location.search)}`
        return (
          <Redirect
            to={{
              pathname: Routes.Login,
              search: queryString,
            }}
          />
        )
      }
    }, [condition, location.pathname, location.search, permissionFailRedirectTo, props, user.isLoggedIn])

    return (
      <LoadingContainer loading={Boolean(!user) || Boolean(!user.firstLoggedIn)} loader={<AppLoader />}>
        {routeComponent}
      </LoadingContainer>
    )
  },
)

export default PrivateRoute
