import React from "react"
import { makeStyles, colors, Grid, Typography, ElementComponentProps } from "@planckdata/react-components"
import { Insight } from "@planckdata/api"
import { Trans, useTranslation } from "i18n"

const useStyles = makeStyles({
  root: {
    paddingBottom: 16,
  },
  title: {
    lineHeight: 1,
    paddingBottom: 12,
  },
})

export interface FeedbackHeaderProps extends ElementComponentProps<HTMLDivElement> {
  insight: Insight
}

export const FeedbackHeader: React.FC<FeedbackHeaderProps> = ({ insight, ...props }) => {
  const { t } = useTranslation()
  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Typography size={20} weight={600} lineHeight={20} className={classes.title}>
          {t("single_business_page.insights.feedback.disagree_title")}
        </Typography>
        <Trans
          i18nKey="single_business_page.insights.feedback.insight_name_value"
          values={{
            insightName: insight.display_name ?? insight.name,
            insightValue: insight.value,
          }}
        >
          <Typography
            size={14}
            weight={400}
            style={{ color: colors.disable, float: "left", marginRight: "1ch" }}
            component="span"
            noWrap
          >
            {insight.display_name ?? insight.name}
          </Typography>
          <Typography
            size={14}
            weight={600}
            style={{
              color: colors.primary,
              float: "left",
              maxWidth: "100%",
            }}
            component="span"
            noWrap
          >
            {insight.value}
          </Typography>
        </Trans>
      </Grid>
    </div>
  )
}
