import React from "react"
import clsx from "clsx"
import { ElementComponentProps, makeStyles, Theme, Tooltip } from "@planckdata/react-components"
import { RiskLevel } from "@planckdata/api"
import { getRiskLevelColor } from "utils"
import RiskFactorFlag from "./RiskFactorFlag"
import { useTranslation } from "i18n"

export interface BusinessInsightsRiskIndicatorProps
  extends Omit<ElementComponentProps<HTMLDivElement, keyof typeof useStyles>, "value">,
    BusinessInsightsRiskIndicatorStyleProps {
  //
}

export interface BusinessInsightsRiskIndicatorStyleProps {
  variant: "small" | "large" | "large-outlined"
  value?: RiskLevel | null
  showTooltip?: boolean
}

const useStyles = makeStyles<Theme, BusinessInsightsRiskIndicatorStyleProps>({
  root: {
    // display: "inline-block",
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: ({ value }) => getRiskLevelColor(value),
  },
})

export const BusinessInsightsRiskIndicator: React.FC<BusinessInsightsRiskIndicatorProps> = (props) => {
  const { className, value, variant, showTooltip, ...rest } = props
  const classes = useStyles(props)
  const { t } = useTranslation()
  const showFlagIcon = variant === "large" || variant === "large-outlined"
  const tooltipText = t(`text_icons.risk_level.${value ?? "none"}`)

  const icon = (
    <div className={clsx(className, classes.root)} {...rest}>
      {showFlagIcon ? <RiskFactorFlag value={value} variant={variant} /> : <div className={classes.dot} />}
    </div>
  )

  if (showTooltip) {
    return <Tooltip title={tooltipText}>{icon}</Tooltip>
  }
  return icon
}

export default BusinessInsightsRiskIndicator
