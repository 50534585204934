import React, { PropsWithChildren } from "react"
import { ExtendedCaseData, Insight } from "@planckdata/api"
import InsightName from "utils/insight-names"

export interface CaseDataContext {
  value: ExtendedCaseData | undefined
  insights: Record<InsightName, Insight> | undefined
  update<K extends keyof ExtendedCaseData>(key: K, data: React.SetStateAction<ExtendedCaseData[K]>): void
}
const CaseDataContext = React.createContext<CaseDataContext>({
  value: undefined,
  insights: undefined,
  update: () => void 0,
})

export const CaseDataProvider: React.FC<PropsWithChildren<CaseDataContext>> = (props) => {
  const { value, update, insights, ...rest } = props
  return <CaseDataContext.Provider value={{ value, insights, update }} {...rest} />
}
export const CaseDataConsumer: React.FC<{ render(value: CaseDataContext): React.ReactNode }> = (props) => (
  <CaseDataContext.Consumer>{(caseData) => props.render(caseData)}</CaseDataContext.Consumer>
)

export function useCaseData(): CaseDataContext {
  return React.useContext(CaseDataContext)
}
