import React from "react"
import { Button, colors, ButtonProps, makeStyles } from "@planckdata/react-components"
import { FileExportIcon } from "@planckdata/react-components/components/icons"
import { useTranslation } from "i18n"

const useStyles = makeStyles({
  FileExportIcon: {
    fontSize: 25,
    color: colors.primary,
  },
})

export interface ExportButtonProps extends ButtonProps {}

export const ExportButton: React.FC<ExportButtonProps> = ({ ...props }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Button
      variant="contained"
      color="default"
      startIcon={<FileExportIcon className={classes.FileExportIcon} />}
      data-testid="ExportCaseButton"
      {...props}
    >
      {t("export_dialog.title")}
    </Button>
  )
}
