import { AnswerCodeNames } from "utils/AnswerCodeNames"
import {
  DbaTextIcon,
  DifferentAddressMatchedTextIcon,
  HighWebPresenceShortTextIcon,
  HighWebPresenceTextIcon,
  HighWebPresenceValueTextIcon,
  LowWebPresenceShortTextIcon,
  LowWebPresenceTextIcon,
  LowWebPresenceValueTextIcon,
  MailingAddressTextIcon,
  MismatchTextIcon,
  NoWebPresenceShortTextIcon,
  NoWebPresenceTextIcon,
  NoWebPresenceValueTextIcon,
  PartialAddressTextIcon,
  PermanentlyClosedTextIcon,
  PropertyOnlyTextIcon,
} from "./AnswerCodes"

export const WebPresenceCode = {
  Low: "low_web_presence",
  No: "no_web_presence",
  High: "high_web_presence",
} as const

export type WebPresenceCode = typeof WebPresenceCode[keyof typeof WebPresenceCode]

export const LOW_WEB_PRESENCE_CODE = WebPresenceCode.Low
export const NO_WEB_PRESENCE_CODE = WebPresenceCode.No
export const HIGH_WEB_PRESENCE_CODE = WebPresenceCode.High

export type AnswerCodeIcon = React.FC<any>

export const answerCodeTextIcons = {
  [WebPresenceCode.Low]: LowWebPresenceTextIcon,
  [WebPresenceCode.No]: NoWebPresenceTextIcon,
  [WebPresenceCode.High]: HighWebPresenceTextIcon,
  [AnswerCodeNames.partial_address]: PartialAddressTextIcon,
  [AnswerCodeNames.permanently_closed]: PermanentlyClosedTextIcon,
  [AnswerCodeNames.mailing_address]: MailingAddressTextIcon,
  [AnswerCodeNames.mismatch_requested_segment]: MismatchTextIcon,
  [AnswerCodeNames.different_address_matched]: DifferentAddressMatchedTextIcon,
  [AnswerCodeNames.dba_is_legal_name]: DbaTextIcon,
  [AnswerCodeNames.lob_only]: PropertyOnlyTextIcon,
} as const

export const answerCodeTextIconsShort = {
  [WebPresenceCode.Low]: LowWebPresenceShortTextIcon,
  [WebPresenceCode.No]: NoWebPresenceShortTextIcon,
  [WebPresenceCode.High]: HighWebPresenceShortTextIcon,
} as const

export const answerCodeTextIconValueOnly = {
  [WebPresenceCode.Low]: LowWebPresenceValueTextIcon,
  [WebPresenceCode.No]: NoWebPresenceValueTextIcon,
  [WebPresenceCode.High]: HighWebPresenceValueTextIcon,
} as const

export const errorAnswerCodesPrioritization = [
  WebPresenceCode.No,
  WebPresenceCode.Low,
  AnswerCodeNames.permanently_closed,
  AnswerCodeNames.lob_only,
] as const
