import React, { useEffect, useState } from "react"
import { colors, Grid, IconButton, makeStyles, PageContentBox, PageContentBoxProps } from "@planckdata/react-components"
import { ThinCrossIcon } from "@planckdata/react-components/components/icons"
import { Feedback, FeedbackStatus, Insight } from "@planckdata/api"
import { FeedbackHeader } from "./FeedbackHeader"
import { EditFeedback } from "./EditFeedback"
import { EvidenceContainer } from "./EvidenceContainer"
import { FeedbackArguments } from "../../../models"

export interface FeedbackContentBoxProps extends PageContentBoxProps {
  insight: Insight
  feedback?: Feedback
  finalDecisionFeedback?: Feedback
  onClose: () => void
  onFeedbackSubmit: (args: FeedbackArguments) => Promise<Feedback>
  onFeedbackBoxOpenSideEffect?: () => void
}

const useStyles = makeStyles(() => ({
  closeIconContainer: {
    position: "absolute",
    right: 4,
    top: 4,
  },
  closeIconButton: {
    strokeWidth: 1,
  },
  ContentBox: {
    background: colors.boxBg,
    padding: "32px 32px 24px",
  },
}))

export const FeedbackContentBox: React.FC<FeedbackContentBoxProps> = ({
  feedback,
  finalDecisionFeedback,
  onFeedbackSubmit,
  onFeedbackBoxOpenSideEffect,
  insight,
  onClose,
  ...props
}) => {
  const classes = useStyles(props)
  const [mode, setMode] = useState<"read" | "edit">("edit")

  useEffect(() => {
    if (insight.evidence && insight.feedback) {
      setMode("read")
    }
  }, [insight.evidence, insight.feedback])

  function onFinalDecision(agree: boolean) {
    const status = agree ? FeedbackStatus.agree : FeedbackStatus.disagree
    onFeedbackSubmit({ status, comment: feedback!.comment!, isFinal: true })
  }

  onFeedbackBoxOpenSideEffect?.()
  return (
    <PageContentBox className={classes.ContentBox}>
      <div className={classes.closeIconContainer}>
        <IconButton className={classes.closeIconButton} onClick={onClose}>
          <ThinCrossIcon />
        </IconButton>
      </div>
      <Grid container>
        <FeedbackHeader insight={insight} />
        {mode === "edit" ? (
          <EditFeedback feedback={feedback} onClose={onClose} onFeedbackSubmit={onFeedbackSubmit} insight={insight} />
        ) : (
          <EvidenceContainer
            feedback={feedback!}
            finalDecisionFeedback={finalDecisionFeedback}
            evidence={insight.evidence!}
            onFinalDecision={onFinalDecision}
          />
        )}
      </Grid>
    </PageContentBox>
  )
}
