import React, { useState } from "react"
import {
  Grid,
  makeStyles,
  colors,
  Typography,
  ErrorDialog,
  CustomComponentProps,
  GridProps,
  Button,
  CircularProgress,
  DialogActions,
  alpha,
  Link,
  sentryLogger,
} from "@planckdata/react-components"
import { Feedback, Evidence } from "@planckdata/api"
import { useTranslation } from "i18n"
import { FeedbackChat } from "./FeedbackChat"
import { MessagesIcon } from "@planckdata/react-components/components/icons"
import { FeedbackButton } from "./FeedbackButton"
import { Routes } from "routes"

const useStyles = makeStyles({
  ChatContainer: {
    minHeight: 260,
    maxHeight: 300,
    overflowY: "auto",
    overflowX: "hidden",
  },
  divider: {
    height: 1,
    backgroundColor: colors.toolTipBackground,
    width: "calc(100% + 64px)",
    margin: "24px -32px 12px",
  },
  iconCircle: {
    lineHeight: "56",
    borderRadius: "50%",
    width: 56,
    height: 56,
    background: colors.boxBg,
    textAlign: "center",
    fontSize: 1,
    color: colors.foregroundText,
    border: `2px solid ${alpha(colors.foregroundText, 0.1)}`,
  },
  icon: {
    height: 30,
    width: 30,
    filter: "grayscale(1)",
  },
  decisionButton: {
    "&:hover": {
      backgroundColor: colors.toolTipBackground,
    },
    backgroundColor: colors.menuItemHighlight,
  },
  feedbackSubmitted: {
    marginBottom: 34,
    fontSize: 18,
    letterSpacing: 0.2,
    lineHeight: "22px",
    color: colors.foregroundText,
  },
  marginTop16: {
    marginTop: 16,
  },
  submitProgress: {
    marginTop: 29,
    marginBottom: 70,
  },
  buttonsContainer: {
    marginBottom: 44,
  },
})

export interface EvidenceContainerProps extends CustomComponentProps<GridProps> {
  feedback: Feedback
  finalDecisionFeedback?: Feedback
  evidence: Evidence
  onFinalDecision: (agree: boolean) => void
}

enum FeedbackAction {
  Initial,
  Submitting,
  Submitted,
}

export const EvidenceContainer: React.FC<EvidenceContainerProps> = ({
  feedback,
  evidence,
  finalDecisionFeedback,
  onFinalDecision,
  ...props
}) => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const [currentAction, setCurrentAction] = useState<FeedbackAction>(
    finalDecisionFeedback ? FeedbackAction.Submitted : FeedbackAction.Initial,
  )
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  async function submitDecision(agree: boolean) {
    setCurrentAction(FeedbackAction.Submitting)
    try {
      onFinalDecision(agree)
      setCurrentAction(FeedbackAction.Submitted)
    } catch (e: any) {
      sentryLogger.logException(e, "Can't set evidence decision")
      setErrorMessage(e.toString())
      setCurrentAction(FeedbackAction.Initial)
    }
  }

  function closeErrorMessage() {
    setErrorMessage(null)
  }

  function getActionsArea() {
    switch (currentAction) {
      case FeedbackAction.Submitted:
        return <ThanksMessage />
      case FeedbackAction.Submitting:
        return <SubmitProgress />
      default:
        return <SubmitButtons onAgree={() => submitDecision(true)} onDisagree={() => submitDecision(false)} />
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} className={classes.ChatContainer}>
        <Grid container>
          <Grid item>
            <FeedbackChat feedback={feedback} evidence={evidence} />
          </Grid>
        </Grid>
      </Grid>
      <>
        <div className={classes.divider}></div>
      </>
      <Grid container direction="column" spacing={2} justifyContent="center">
        {getActionsArea()}
        <Grid item>
          <Typography size={14} color="textSecondary" align="center">
            {t("single_business_page.insights.feedback.feedback_footnote")}{" "}
            <Link
              style={{ color: colors.foregroundText }}
              to={Routes.Support + "?" + new URLSearchParams({ topic: `Feedback ${feedback.entity_id}` }).toString()}
            >
              {t("single_business_page.insights.feedback.feedback_footnote_contact_us")}
            </Link>
            .
          </Typography>
        </Grid>
      </Grid>
      <ErrorDialog
        title="Error"
        open={!!errorMessage}
        onClose={closeErrorMessage}
        dialogActions={
          <DialogActions>
            <Button onClick={closeErrorMessage} variant="contained" color="primary">
              {t("general.close_button_text")}
            </Button>
          </DialogActions>
        }
      >
        {errorMessage}
      </ErrorDialog>
    </Grid>
  )
}

interface SubmitButtonsProps {
  onAgree: () => void
  onDisagree: () => void
}

const SubmitButtons: React.FC<SubmitButtonsProps> = ({ onAgree, onDisagree }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <>
      <Grid item style={{ marginBottom: 24 }}>
        {t("single_business_page.insights.feedback.final_decision_prompt")}
      </Grid>
      <Grid container spacing={1} justifyContent="center" className={classes.buttonsContainer}>
        <Grid item>
          <FeedbackButton className={classes.decisionButton} onClick={onAgree}>
            {t("single_business_page.insights.feedback.final_decision_agree")}
          </FeedbackButton>
        </Grid>
        <Grid item>
          <FeedbackButton className={classes.decisionButton} onClick={onDisagree}>
            {t("single_business_page.insights.feedback.final_decision_disagree")}
          </FeedbackButton>
        </Grid>
      </Grid>
    </>
  )
}

const SubmitProgress: React.FC<any> = (props) => {
  const classes = useStyles(props)
  return (
    <Grid container direction="column" alignItems="center" justifyContent="center">
      <Grid item className={classes.submitProgress}>
        <div>
          <CircularProgress />
        </div>
      </Grid>
    </Grid>
  )
}

const ThanksMessage: React.FC<any> = (props) => {
  const classes = useStyles(props)
  const { t } = useTranslation()

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center">
      <Grid item className={classes.marginTop16}>
        <div className={classes.iconCircle}>
          <MessagesIcon className={classes.icon} />
        </div>
      </Grid>
      <Grid item className={classes.marginTop16}>
        <div className={classes.feedbackSubmitted}>
          {t("single_business_page.insights.feedback.feedback_submitted")}
        </div>
      </Grid>
    </Grid>
  )
}
