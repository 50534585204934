import React from "react"
import { LoginHook, useLogin } from "./Login.hook"
import { UserStore } from "@planckdata/storage"
import { mixpanelIdentify } from "analytics"

export interface AppRouterHook {
  loginProps: LoginHook
}

export function useAppRouter(): AppRouterHook {
  const [loading, setLoading] = React.useState(true)

  const loginProps = useLogin({
    loading,
    showResetPassword: true,
    onLogin,
    onResetPassword,
    onUserInit,
  })

  function onUserInit(user: UserStore) {
    setLoading(false)
    if (user.isLoggedIn) {
      mixpanelIdentify(user.current!.username)
    }
  }

  function onLogin(username: string) {
    mixpanelIdentify(username)
    setLoading(false)
  }

  function onResetPassword() {
    setLoading(false)
  }

  return {
    loginProps,
  }
}
