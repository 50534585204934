import React from "react"
import clsx from "clsx"
import { ElementComponentProps, colors, createTransition, makeStyles, Theme } from "@planckdata/react-components"
import { SideMenu, DRAWER_CLOSED_WIDTH, useDrawerOpenWidth } from "components"
import { I18nNSContext, Namespace } from "@planckdata/i18n"
import backgroundImage from "../../../assets/background.svg"
import { ErrorPageProvider } from "../../../pages/ErrorPages"

export const CONTENT_MAX_WIDTH = 1920 - DRAWER_CLOSED_WIDTH - 32

export interface MainLayoutProps extends Omit<ElementComponentProps<HTMLDivElement, keyof typeof useStyles>, "width"> {
  ns: Namespace
}

export interface MainLayoutStyleProps {
  sidebarWidth: number
}

const useStyles = makeStyles<Theme, MainLayoutStyleProps>((theme) => ({
  "@global": {
    body: {
      backgroundImage: `url(${backgroundImage}), linear-gradient(180deg, ${colors.accentBg} 0%, #edf0f4 100%)`,
      backgroundPosition: "top center",
      backgroundRepeat: "repeat-y, no-repeat",
      minHeight: "100vh",
      backgroundAttachment: "fixed",
    },
  },
  root: {
    width: "100%",
    height: "100%",
  },
  body: {
    height: "100vh",
    color: colors.foregroundText,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: `calc(100% - ${DRAWER_CLOSED_WIDTH}px)`,
    marginLeft: `calc(${DRAWER_CLOSED_WIDTH}px)`,
    padding: theme.spacing(0, 5),
    transition: createTransition(["margin-left", "width"], {
      duration: 300,
      easing: "easeInOut",
    }),
  },
  content: {
    width: "100%",
    "& > *": {
      margin: "0 auto",
      maxWidth: CONTENT_MAX_WIDTH,
    },
  },
  sidebar: {},
  sidebarOpen: {
    width: (props) => `calc(100% - ${props.sidebarWidth}px)`,
    marginLeft: (props) => `calc(${props.sidebarWidth}px)`,
  },
}))

export const _MainLayout: React.FC<MainLayoutProps> = (props) => {
  const { children, className, ns } = props
  const [sidebarOpen, setSidebarOpen] = React.useState(false)
  const drawerOpenWidth = useDrawerOpenWidth()
  const classes = useStyles({ ...props, sidebarWidth: drawerOpenWidth })

  return (
    <>
      <SideMenu onTogglePin={setSidebarOpen} />
      <div className={clsx(classes.body, classes.sidebar, { [classes.sidebarOpen]: sidebarOpen })}>
        <div className={clsx(classes.content, className)}>
          <I18nNSContext.Provider value={ns}>
            <ErrorPageProvider>{children}</ErrorPageProvider>
          </I18nNSContext.Provider>
        </div>
      </div>
    </>
  )
}

export const MainLayout = React.memo(_MainLayout, () => false)

export default MainLayout
