import React, { ReactElement, useEffect, useState } from "react"
import {
  AppDialog,
  AppDialogButton,
  Box,
  Checkbox,
  colors,
  CustomMUIComponentProps,
  DialogProps,
  FormControlLabel,
  FormGroup,
  LoadingContainer,
  PromiseOr,
  Radio,
  RadioGroup,
  Typography,
} from "@planckdata/react-components"
import { CheckIcon, FileExportIcon } from "@planckdata/react-components/components/icons"
import { useTranslation } from "i18n"
import { InsightsExportOptions } from "./ExportComponent"

export type ExportFileFormat = "xlsx" | "pdf"
const ExportFormats: Record<ExportFileFormat, string> = {
  xlsx: "XLSX",
  pdf: "PDF",
}

const DEFAULT_EXPORT_FORMAT = "xlsx"

export interface ExportDialogProps extends CustomMUIComponentProps<DialogProps> {
  exportFunction: (options: InsightsExportOptions) => PromiseOr<void>
  beforeExportText?: ReactElement<string> | string
  beforeExportTitle?: ReactElement<string> | string
  afterExportTitle?: ReactElement<string> | string
  afterExportText?: ReactElement<string> | string
  afterExportButtonText?: ReactElement<string> | string
  beforeExportButtonText?: ReactElement<string> | string
  renderExportWithFeedback?: boolean
  exportFormats?: ExportFileFormat[]
}

export const ExportDialog: React.FC<ExportDialogProps> = ({
  open,
  beforeExportText = "",
  beforeExportTitle,
  afterExportTitle = "",
  afterExportText = "",
  afterExportButtonText = "",
  beforeExportButtonText,
  renderExportWithFeedback = true,
  ...props
}) => {
  const { t } = useTranslation()
  const [withFeedback, setWithFeedback] = useState(renderExportWithFeedback)
  const [exportFinished, setExportFinished] = useState(false)
  const [loading, setLoading] = useState(false)
  const [exportFormat, setExportFormat] = useState<ExportFileFormat>(DEFAULT_EXPORT_FORMAT)
  const [hideEmptyValues, setHideEmptyValues] = useState(false)
  const title = React.useMemo(
    () => (exportFinished ? afterExportTitle : beforeExportTitle || t("export_dialog.title")),
    [afterExportTitle, beforeExportTitle, exportFinished, t],
  )
  const body = React.useMemo(
    () => (exportFinished ? afterExportText : beforeExportText),
    [afterExportText, beforeExportText, exportFinished],
  )
  const finishButtonText = React.useMemo(
    () => (exportFinished ? afterExportButtonText : beforeExportButtonText || t("export_dialog.title")),
    [afterExportButtonText, beforeExportButtonText, exportFinished, t],
  )
  const icon = React.useMemo(
    () => (exportFinished ? <CheckIcon fontSize="inherit" /> : <FileExportIcon fontSize="inherit" />),
    [exportFinished],
  )
  const iconColor = React.useMemo(() => (exportFinished ? colors.success : colors.primary), [exportFinished])

  useEffect(() => {
    setHideEmptyValues(exportFormat === "pdf")
  }, [exportFormat])

  const exportFunction = React.useCallback(async () => {
    setLoading(true)
    try {
      await props.exportFunction({ format: exportFormat, hide_empty_values: hideEmptyValues, feedback: withFeedback })
      setLoading(false)
      setExportFinished(true)
    } finally {
      setLoading(false)
    }
  }, [exportFormat, hideEmptyValues, props, withFeedback])

  useEffect(() => {
    if (open) {
      setExportFinished(false)
    }
  }, [open])

  const buttonAction = React.useMemo(
    () =>
      exportFinished ? (e: React.MouseEvent<HTMLButtonElement>) => props.onClose?.(e, "escapeKeyDown") : exportFunction,
    [exportFinished, exportFunction, props],
  )

  const handleExportFormatChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setExportFormat((event.target as HTMLInputElement).value as ExportFileFormat)
  }, [])

  const exportFormatRadioList = React.useMemo(
    () =>
      exportFinished ? null : (
        <FormGroup hidden={exportFinished} style={{ alignItems: "center" }}>
          <FormControlLabel
            control={
              <Checkbox checked={withFeedback} color="primary" onChange={(_, checked) => setWithFeedback(checked)} />
            }
            label={<Typography size={16}>{t("export_dialog.options_prod")}</Typography>}
          />
          <RadioGroup row={true} value={exportFormat} onChange={handleExportFormatChange}>
            {props.exportFormats?.length !== undefined && props.exportFormats?.length > 1
              ? props.exportFormats!.map((format) => {
                  return (
                    <FormControlLabel
                      key={format}
                      value={format}
                      control={<Radio />}
                      label={<Typography size={16}>{ExportFormats[format]}</Typography>}
                    />
                  )
                })
              : null}
          </RadioGroup>
          <Box height={16} />
        </FormGroup>
      ),
    [exportFinished, exportFormat, handleExportFormatChange, props.exportFormats, t, withFeedback],
  )

  const actions = (
    <LoadingContainer loading={loading}>
      <AppDialogButton onClick={buttonAction} variant="contained" color="primary" data-testid="ExportDialogButton">
        {finishButtonText}
      </AppDialogButton>
    </LoadingContainer>
  )

  return (
    <AppDialog
      open={open}
      dialogTitle={title}
      icon={icon}
      iconColor={iconColor}
      content={body}
      bottomContent={renderExportWithFeedback ? exportFormatRadioList : null}
      actions={actions}
      onClose={props.onClose}
      data-testid="ExportFinishDialog"
    />
  )
}

ExportDialog.defaultProps = {
  exportFormats: [DEFAULT_EXPORT_FORMAT],
}
