import React from "react"
import clsx from "clsx"
import SideMenuHeader from "./SideMenuHeader"
import { Divider, Drawer, DrawerProps, List, makeStyles, UserAvatar, EventHandler } from "@planckdata/react-components"
import { MenuItems } from "./MenuItems"
import SideMenuItem, { SideMenuItemProps } from "./SideMenuItem"
import { useTranslation } from "i18n"
import { useUser } from "user-context"
import { observer } from "mobx-react-lite"
import { ProfileBox } from "../ProfileBox"

export const DRAWER_CLOSED_WIDTH = 64
const DEFAULT_DRAWER_OPEN_WIDTH = 265

const LocaleDrawerWidths = {
  "en-US": DEFAULT_DRAWER_OPEN_WIDTH,
  "de-DE": 310,
}

const drawerOpenWidth = (locale: string): number =>
  LocaleDrawerWidths[locale as keyof typeof LocaleDrawerWidths] ?? DEFAULT_DRAWER_OPEN_WIDTH

export function useDrawerOpenWidth(): number {
  const { i18n } = useTranslation()
  return drawerOpenWidth(i18n.language)
}
export interface SideMenuProps extends DrawerProps {
  items?: SideMenuItemProps[]
  onToggle?(open: boolean): void
  onTogglePin?(pinned: boolean): void
}

export interface SideMenuStyleProps extends SideMenuProps {
  width: number
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  drawer: {
    width: (props: SideMenuStyleProps) => props.width,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: (props: SideMenuStyleProps) => props.width,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: DRAWER_CLOSED_WIDTH,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  spacer: {
    flex: 1,
  },
  list: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  userName: {
    fontWeight: 600,
  },
  avatar: {
    marginRight: 12,
  },
  avatarDrawerClosed: {
    marginLeft: -8,
  },
  menu: {
    marginLeft: 52,
  },
}))

function getCachedPinStatus(): boolean {
  return window.localStorage.getItem("sidebar_pinned") === "true"
}

function setCachedPinStatus(state: boolean): void {
  window.localStorage.setItem("sidebar_pinned", String(state))
}

let hoverPersistence = false

export const SideMenu: React.FC<SideMenuProps> = observer(({ items: _items, onToggle, onTogglePin, ...props }) => {
  const drawerOpenWidth = useDrawerOpenWidth()
  const classes = useStyles({ ...props, width: drawerOpenWidth })
  const cachedPinned = getCachedPinStatus()
  const [open, setOpen] = React.useState(cachedPinned || hoverPersistence)
  const [pinned, setPinned] = React.useState(cachedPinned)
  const user = useUser()

  const items = (_items ?? MenuItems).filter(
    (item) =>
      [undefined, true].includes(item.visible as any) ||
      (typeof item.visible === "function" && item.visible(user.current)),
  )

  React.useEffect(() => {
    onToggle?.(open)
  }, [onToggle, open])

  React.useEffect(() => {
    onTogglePin?.(pinned)
    setCachedPinStatus(pinned)
    window.dispatchEvent(new Event("SidebarToggle"))
  }, [onTogglePin, pinned])

  const handleDrawerOpen = React.useCallback(() => {
    setOpen(true)
    hoverPersistence = true
  }, [])

  const handleDrawerClose = React.useCallback(() => {
    setOpen(pinned)
    hoverPersistence = false
  }, [pinned])

  const handleDrawerPinned = React.useCallback(() => {
    setPinned(!pinned)
  }, [pinned])

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      onMouseEnter={handleDrawerOpen}
      onMouseLeave={handleDrawerClose}
      {...props}
    >
      <SideMenuHeader isOpen={open} isPinned={pinned} drawerPinnedHandler={handleDrawerPinned} />

      <Divider />
      <List className={classes.list}>
        {items.map((item, index) => (
          <SideMenuItem key={index} sideMenuOpen={open} {...item} />
        ))}
        <div className={classes.spacer} />
        {user.current ? (
          <ProfileBox
            classes={{ paper: classes.menu }}
            userMenuProps={{
              popoverProps: {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                onClose: () => {
                  if (open && !pinned) {
                    setOpen(false)
                  }
                },
              },
            }}
          >
            <SideMenuItem
              icon={
                <UserAvatar
                  className={clsx(classes.avatar, { [classes.avatarDrawerClosed]: !open })}
                  userName={user.current.username}
                  color="primary"
                  initialsCount={1}
                />
              }
              route="#user-menu"
              sideMenuOpen={open}
              text={<span className={classes.userName}>{user.current.username}</span>}
              textSecondary={user.current.internal ? user.current.orgName : undefined}
              noWrap
              onClick={EventHandler.prevent()}
            />
          </ProfileBox>
        ) : null}
      </List>
    </Drawer>
  )
})

export default SideMenu
