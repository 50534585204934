import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { TextField } from "@planckdata/react-components"
import { useStyles } from "./Styles.hook"
import { RemoveItemButton, AddNewButton, ClearValueButton } from "./buttons"

export interface ObjectListField<T = any> {
  key: keyof T
  title: string
}

export interface ObjectListHoverEditProps<T = any> {
  fields: Array<ObjectListField<T>>
  value: Array<T>
  onChange: (values: Array<T>) => void
  naValue?: string
}

export const ObjectListHoverEdit: React.FC<ObjectListHoverEditProps> = ({
  fields,
  value,
  onChange,
  naValue,
  ...props
}) => {
  const classes = useStyles(props)
  const [hover, setHover] = useState(false)
  const [editedValues, setEditedValues] = useState(value)

  useEffect(() => {
    setEditedValues(value)
  }, [value])

  function updateItem(index: number, value: any) {
    editedValues[index] = value
    setEditedValues(editedValues.slice())
    onChange(editedValues)
  }

  function handleRemoveItem(index: number) {
    editedValues.splice(index, 1)
    setEditedValues(editedValues.slice())
    onChange(editedValues)
  }

  function addItem() {
    setEditedValues([...editedValues, {}])
  }

  function renderObjects() {
    return editedValues.map((v, i) => (
      <div style={{ marginBottom: 16 }} key={i}>
        <SingleObject
          index={i + 1}
          fields={fields}
          value={v}
          hover={hover}
          onChange={(val) => updateItem(i, val)}
          onRemove={() => handleRemoveItem(i)}
        />
      </div>
    ))
  }

  function renderNA() {
    return (
      <TextField
        className={clsx(classes.root, classes.text)}
        variant="standard"
        InputProps={{
          disableUnderline: true,
        }}
        value={naValue}
        style={{ pointerEvents: "none" }}
      />
    )
  }

  return (
    <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {naValue && editedValues.length === 0 ? renderNA() : renderObjects()}
      <div className={classes.row}>
        <AddNewButton onClick={() => addItem()} visible={hover} />
      </div>
    </div>
  )
}

export interface SingleObjectProps<T = any> {
  index: number
  fields: Array<ObjectListField<T>>
  value: T
  hover?: boolean
  onChange: (value: T) => void
  onRemove: () => void
}

export const SingleObject: React.FC<SingleObjectProps> = ({
  index,
  fields,
  value,
  hover,
  onChange,
  onRemove,
  ...props
}) => {
  const classes = useStyles(props)
  const [focused, setFocused] = useState(false)
  const [edited, setEdited] = useState(value)

  function handleBlur(key: keyof typeof value, newValue: string) {
    edited[key] = newValue
    setEdited({ ...edited })
    setFocused(false)
    onChange(edited)
  }

  function handleChange(key: keyof typeof value, newValue: string) {
    edited[key] = newValue
    setEdited({ ...edited })
  }

  function renderField(field: ObjectListField, value?: string) {
    return (
      <div className={classes.row} key={field.key.toString()}>
        <TextField
          fullWidth
          className={clsx(classes.root, classes.text, {
            [classes.hover]: hover,
            [classes.focused]: focused,
          })}
          onChange={(e) => handleChange(field.key, e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={(e) => handleBlur(field.key, e.target.value)}
          variant="standard"
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <ClearValueButton
                onClick={() => handleBlur(field.key, "")}
                visible={!!value?.trim() && (hover || focused)}
              />
            ),
          }}
          label={value?.trim() ? field.title : undefined}
          value={value ?? ""}
          placeholder={field.title}
          InputLabelProps={{ className: classes.objectListLabel }}
        />
      </div>
    )
  }

  return (
    <div style={{ display: "flex" }}>
      <div>
        <span className={classes.index}>{index}.</span>
        <RemoveItemButton onClick={() => onRemove()} visible={hover || focused} />
      </div>
      <div style={{ flex: "1" }}>{fields.map((field) => renderField(field, edited[field.key]))}</div>
    </div>
  )
}
