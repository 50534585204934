import React from "react"
import BatchSuccessBox from "./BatchSuccessBox"
import BatchErrorBox from "./BatchErrorBox"
import BatchWarningBox from "./BatchWarningBox"
import { CombinedInvalidCasesSummary, InvalidCaseSummary } from "@planckdata/api"

export interface BatchStatusResponse {
  status: "success" | "warning" | "error"
  fileName: string
  onFileSelected: any
  totalCount?: number
  invalidCount?: number
  errorSummaryPerRow?: InvalidCaseSummary[]
  errorSummaryCombined?: CombinedInvalidCasesSummary[]
  downloadLink?: string
  errorText?: string
  acceptFileTypes?: string
  validateBatchErrorResponse?: string
}

export const BatchStatusBox: React.FC<BatchStatusResponse> = ({
  status,
  fileName,
  onFileSelected,
  totalCount,
  invalidCount,
  errorSummaryPerRow = [],
  errorSummaryCombined = [],
  acceptFileTypes,
  downloadLink,
  validateBatchErrorResponse,
}) => {
  if (status === "success") {
    return <BatchSuccessBox fileName={fileName} onFileSelected={onFileSelected} acceptFileTypes={acceptFileTypes} />
  } else if (status == "error") {
    return (
      <BatchErrorBox
        fileName={fileName}
        onFileSelected={onFileSelected}
        acceptFileTypes={acceptFileTypes}
        validateBatchErrorResponse={validateBatchErrorResponse}
      />
    )
  } else if (status == "warning") {
    return (
      <BatchWarningBox
        totalCount={totalCount!}
        invalidCount={invalidCount!}
        errorSummaryPerRow={errorSummaryPerRow}
        errorSummaryCombined={errorSummaryCombined}
        downloadLink={downloadLink!}
        fileName={fileName}
        onFileSelected={onFileSelected}
        acceptFileTypes={acceptFileTypes}
      />
    )
  } else {
    return <div />
  }
}

export default BatchStatusBox
