import React from "react"
import clsx from "clsx"
import { ElementComponentProps, makeStyles, Grid } from "@planckdata/react-components"

export interface DotWithTextProps extends ElementComponentProps<HTMLDivElement> {
  color: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(-2, -1),
  },
  dot: {
    borderRadius: "50%",
    width: 7,
    height: 7,
    float: "left",
  },
}))

export const DotWithText: React.FC<DotWithTextProps> = ({ color, className, ...props }) => {
  const classes = useStyles(props)

  return (
    <Grid className={clsx(className, classes.root)} container alignItems="center" spacing={2}>
      <Grid item>
        <span className={classes.dot} style={{ backgroundColor: color }} />
      </Grid>
      <Grid item>{props.children}</Grid>
    </Grid>
  )
}

export default DotWithText
