import { AnswerCode, CaseData, FilterOptions, SortOptions } from "@planckdata/api"
import { WebPresenceCode } from "components"
import { CaseSortBy, SelectedFiltersValues } from "models/cases-filters"
import { AnswerCodeNames } from "./AnswerCodeNames"

export function getWebPresence(caseData: CaseData | undefined | null): WebPresenceCode {
  return (caseData?.answer_codes?.find((ac) => ac.code === WebPresenceCode.Low || ac.code === WebPresenceCode.No)
    ?.code || WebPresenceCode.High) as WebPresenceCode
}

export function createCasesRequest<T extends Partial<FilterOptions & SortOptions>>(
  request: T,
  filterString?: string,
  filters?: SelectedFiltersValues,
  sortBy?: CaseSortBy,
): T {
  request.business_name = filterString ? filterString : undefined

  if (sortBy) {
    request.sort_field = sortBy.field
    request.sort_direction = sortBy.order.toUpperCase() as any
  }

  if (filters) {
    const temp: any = request
    for (const f in filters) {
      if (filters[f] != null) {
        temp[f] = filters[f]
      }
    }
    request = temp
  }

  return request
}

export function patchAnswerCodes(answerCodes: AnswerCode[] | undefined): AnswerCode[] | undefined {
  return answerCodes?.map((p) => {
    if (p.code === AnswerCodeNames.property_only) {
      p.code = AnswerCodeNames.lob_only
    }
    return p
  })
}
