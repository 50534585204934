import { LoginFormProps, sentryLogger } from "@planckdata/react-components"
import { UserStore } from "@planckdata/storage"
import React from "react"
import { Routes } from "routes"
import { useUser } from "user-context"
import { getHomeRoute } from "utils"
import {takeQueryStringValue} from "@planckdata/typescript-utils"

export type LoginHook = Omit<LoginFormProps, "mode">

export interface LoginOptions {
  loading: boolean
  showResetPassword: boolean
  onLogin: (username: string) => void
  onResetPassword: () => void
  onUserInit: (user: UserStore) => void
}

export function useLogin(options: LoginOptions): LoginHook {
  const { loading, showResetPassword, onLogin, onResetPassword, onUserInit } = options
  const user = useUser()

  async function checkSession() {
    try {
      await user.checkSession()
    } catch (e) {
      refreshUserToken()
    }

    startPeriodicCheck()
  }

  async function refreshUserToken() {
    try {
      await user.refreshUserToken()
    } catch (e) {
      sentryLogger.logException(e, "user.refreshUserToken failed")
    }
  }

  function startPeriodicCheck() {
    setTimeout(checkSession, 12 * 60 * 60 * 1000)
  }

  React.useEffect(() => {
    user
      .initializeClient()
      .catch((e) => sentryLogger.logException(e, "User init error"))
      .then(() => {
        startPeriodicCheck()
        onUserInit(user)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loginProps: LoginHook = {
    onLogin: async (details) => {
      const returnTo = takeQueryStringValue("returnTo")
      const loginParams = ({...details, ...(returnTo ? {redirectUri:window.location.href} : {})})
      await user.requestLogin(loginParams)
      onLogin(details.email)
    },
    onRegister: async () => console.warn("Not implemented: Register"),
    onResetPassword: async (details) => {
      const res = await user.requestPasswordChange(details)
      onResetPassword()
      return res
    },
    isLoggedIn: user.isLoggedIn,
    loading: loading,
    routes: {
      home: getHomeRoute(user.current),
      login: Routes.Login,
      passwordReset: Routes.ForgotPassword,
      register: Routes.Register,
    },
    showResetPassword,
  }
  return loginProps
}
