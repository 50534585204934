import { observer } from "mobx-react-lite"
import React from "react"
import { CustomComponentProps, GridProps, Header as OrigHeader, makeStyles } from "@planckdata/react-components"
import clsx from "clsx"
import { ProfileBox } from "./ProfileBox"

const useStyles = makeStyles(() => ({
  root: {
    flexShrink: 0,
    padding: 0,
  },
}))

export const Header: React.FC<CustomComponentProps<GridProps>> = observer(({ className, ...props }) => {
  const classes = useStyles(props)

  return (
    <OrigHeader className={clsx(className, classes.root)} {...props}>
      <ProfileBox />
    </OrigHeader>
  )
})

export default Header
