import React from "react"
import { useHistory } from "react-router"
import useTrackEvents from "hooks/track-events.hook"

export function usePageView(): void {
  const { trackPageView } = useTrackEvents()
  const history = useHistory()

  React.useEffect(() => {
    function handleLocationChanged(event: any, action: string) {
      if (action !== "REPLACE" || event.state?.shouldTrack === true) {
        trackPageView()
      }
    }

    trackPageView()
    return history.listen(handleLocationChanged)
  }, [history, trackPageView])
}
