import {
  Insight,
  InsightValue as InsightValueField,
  InsightValueType,
  RiskLevel,
  SelectedRiskFactor,
} from "@planckdata/api"
import { colors } from "@planckdata/react-components"
import InsightName from "./insight-names"

export function convertValueToList(editedValue: string, insightName: string, delimiter = "|"): Insight["values"] {
  return editedValue.split(delimiter).map<InsightValueField>((val) =>
    buildInsight({
      name: insightName,
      expected_value_type: InsightValueType.StringAttribute,
      value: val.trim(),
    }),
  )
}

const DEFAULT_INSIGHT_VALUES: Insight = {
  display_name: "",
  evidence: null,
  feedback: null,
  final_decision_feedback: null,
  possible_values: "",
  type: null,
  user_editable: false,
  package: "",
  confidence: null,
  expected_value_type: InsightValueType.StringAttribute,
  name: "",
  value: "",
  values: null,
}

export function buildInsight<T extends Insight>(params: Partial<T>): T {
  return { ...DEFAULT_INSIGHT_VALUES, ...params } as T
}

// BUCKETS
export interface BucketInfo<T> {
  from: number
  to: number
  key: T
}

function getBucket<T = any>(buckets: Array<BucketInfo<T>>, value: number | null): BucketInfo<T> | null {
  if (value == null) {
    value = 0
  }
  for (let i = 0; i < buckets.length; i++) {
    const bucket = buckets[i]
    if (value >= bucket.from && value <= bucket.to) {
      return bucket
    }
  }

  return null
}

export enum ConfidenceBucketKey {
  Low = "low",
  MediumLow = "medium_low",
  Medium = "medium",
  High = "high",
}

export interface ConfidenceBucket extends BucketInfo<ConfidenceBucketKey> {}

export const CONFIDENCE_BUCKETS: Array<ConfidenceBucket> = [
  { from: 0.5, to: 0.65, key: ConfidenceBucketKey.Low },
  { from: 0.66, to: 0.8, key: ConfidenceBucketKey.MediumLow },
  { from: 0.81, to: 0.9, key: ConfidenceBucketKey.Medium },
  { from: 0.91, to: 1, key: ConfidenceBucketKey.High },
]

export function getConfidenceBucket(confidence: number): ConfidenceBucket | null {
  return getBucket(CONFIDENCE_BUCKETS, confidence)
}

export enum NaicsBucketKey {
  Low = "low",
  Medium = "medium",
  High = "high",
}

export interface NaicsBucket extends BucketInfo<NaicsBucketKey> {}

export const NAICS_BUCKETS: Array<NaicsBucket> = [
  { from: 0, to: 0.34, key: NaicsBucketKey.Low },
  { from: 0.35, to: 0.68, key: NaicsBucketKey.Medium },
  { from: 0.69, to: 1, key: NaicsBucketKey.High },
]

export function getNaicsBucket(value: number | null): NaicsBucket | null {
  return getBucket(NAICS_BUCKETS, value)
}

// TODO: use Pick from InsightName
export type NaicsInsightName = "NAICSClassificationWithConfidence" | "NAICSClassification"

export function isEmptyInsightValue(value: string): boolean {
  return !value.trim() || value.trim() === "N/A"
}

export function isConfidenceInsight(insight: InsightValueField): boolean {
  return insight.name.toLowerCase().includes("confidence")
}

/**
 * Check if risk factor matches insight by display_name & package
 *
 * @returns true if risk factor is same insight, false otherwise.
 */
export function matchInsightRiskFactor(insight: Insight, riskFactor: SelectedRiskFactor): boolean {
  return (
    insight && riskFactor && insight.display_name === riskFactor.display_name && insight.package === riskFactor.package
  )
}

export type RiskFactorAndInsight = Insight & SelectedRiskFactor

/**
 * Build risk factor insights
 * @param insights Available insights
 * @param riskFactors Configured risk factors
 * @returns An array of matched risk factors and insights
 */
export function mergeRiskFactors(
  insights: Array<Insight>,
  riskFactors: Array<SelectedRiskFactor>,
): Array<RiskFactorAndInsight> {
  return riskFactors
    .map((rf) => {
      const ins = insights.find((insight) => matchInsightRiskFactor(insight, rf))
      if (!ins) {
        return null
      }
      return {
        ...DEFAULT_INSIGHT_VALUES,
        ...rf,
        ...ins,
      }
    })
    .filter(Boolean) as Array<RiskFactorAndInsight>
}

/**
 * Extract selected insights from insights list
 * @param insights
 * @param names
 * @returns Array of insights (or null if not found) ordered by supplied names
 *
 * @example
 *
 * const [businessName, mainAddress, ...locationInsights] = React.useMemo(() => {
 *   const requiredInsights = [InsightName.doing_business_as, InsightName.main_address, ...locationInsightsNames]
 *   return getInsights(insights, requiredInsights)
 * }, [insights])
 */
export function getInsights(insightsMap: Record<InsightName, Insight>, names: Array<InsightName>): Array<Insight | null>
export function getInsights(insights: Array<Insight>, names: Array<InsightName>): Array<Insight | null>
export function getInsights(
  insights: Array<Insight> | Record<InsightName, Insight>,
  names: Array<InsightName>,
): Array<Insight | null> {
  if (!Array.isArray(insights)) {
    return names.map((p) => insights[p] ?? null)
  }
  const result: Array<Insight | null> = new Array(names.length).fill(null)
  const indexes = names.reduce((acc, cur, i) => {
    acc[cur as string] = i
    return acc
  }, {} as Record<string, number>)
  for (let i = 0; i < insights.length; i++) {
    const insight = insights[i]
    for (let j = 0; j < names.length; j++) {
      if (insight.name === names[j]) {
        result[indexes[insight.name]] = insight
        names.splice(j, 1)
        break
      }
    }
    if (names.length === 0) {
      break
    }
  }

  return result
}

const riskLevelColorMap = {
  [RiskLevel.none]: "#95999A",
  [RiskLevel.low]: colors.success,
  [RiskLevel.medium]: colors.warning,
  [RiskLevel.high]: "#EB5757",
}

export function getRiskLevelColor(riskLevel?: RiskLevel | null): string {
  return riskLevelColorMap[riskLevel ?? "none"]
}

const riskLevelBorderColorMap = {
  [RiskLevel.none]: "#E0DCDC",
  [RiskLevel.low]: colors.success,
  [RiskLevel.medium]: colors.warning,
  [RiskLevel.high]: "#EB5757",
}

export function businessRiskLevelBorderColor(riskLevel?: RiskLevel | null): string {
  return riskLevelBorderColorMap[riskLevel ?? "none"]
}
