import { CheckIcon } from "@planckdata/react-components/components/icons"
import React from "react"
import { colors, ElementComponentProps, alpha, makeStyles } from "@planckdata/react-components"
import cls from "clsx"

const useStyles = makeStyles({
  CheckIcon: {
    verticalAlign: "middle",
    fontSize: 12,
    display: "inline-block",
  },
  CheckContainer: {
    borderRadius: "50%",
    color: colors.success,
    background: alpha(colors.success, 0.2),
    lineHeight: 0,
    paddingTop: 3,
    width: 17,
    height: 17,
  },
  SuccessColor: {
    color: colors.success,
    background: alpha(colors.success, 0.2),
  },
  DisabledColor: {
    color: colors.disable,
    background: alpha(colors.disable, 0.2),
  },
})

export const SuccessCheck: React.FC<ElementComponentProps<HTMLDivElement>> = ({ disabled, className, ...props }) => {
  const classes = useStyles(props)

  return (
    <div
      {...(props as any)}
      className={cls(classes.CheckContainer, className, disabled ? classes.DisabledColor : classes.SuccessColor)}
    >
      <CheckIcon className={classes.CheckIcon} />
    </div>
  )
}
