import React from "react"
import { Trans, useTranslation } from "../../../i18n"
import {
  Button,
  CustomComponentProps,
  ElementComponentProps,
  ErrorDialog,
  Grid,
  makeStyles,
  PageContentBox,
  ResultDialogProps,
  Theme,
  Typography,
} from "@planckdata/react-components"
import clsx from "clsx"
import { ErrorPageContext } from "../../../pages/ErrorPages"
import { Routes } from "../../../routes"

export interface CustomErrorPageProps
  extends Omit<ElementComponentProps<HTMLDivElement, keyof typeof useStyles>, "title">,
    CustomErrorProps,
    CustomErrorStyleProps {
  //
}
export interface CustomErrorDialogProps
  extends Omit<CustomComponentProps<ResultDialogProps, keyof typeof useStyles>, "title">,
    Omit<CustomErrorProps, "image">,
    CustomErrorStyleProps {
  //
}

export interface CustomErrorProps {
  /**
   * Image SVG source, use `import Image from 'image.svg'`, or custom components. Any string will be interpolated as
   * `src` for the `img` element, so if you need a string, wrap it in an element or a Fragment.
   */
  image: React.ReactNode
  /**
   * Title of the error, e.g. `"Page Not Found"`. If string is provided, will be treated as `i18n` key and passed
   * to `t`.
   */
  title: React.ReactNode
  /** Description of the error. If string is provided, will be treated as `i18n` key and passed to `t`. */
  description: React.ReactNode
  /**
   * CTA button. Use `ctx.clearError` to remove the error from the context when the CTA is pressed.
   *
   * Can be used both as a component or a render prop. Example:
   *
   * ```tsx
   * // As a render prop
   * <CustomErrorPage
   *  cta={({ ctx }) => (
   *   <Button onClick={ctx.clearError}>Go back</Button>
   * )} />
   *
   * // Pass a component directly
   * const GoBackButton: React.FC<{ ctx: ErrorPageContext }> = ({ ctx }) => (
   *   <Button onClick={ctx.clearError}>Go back</Button>
   * )
   * <CustomErrorPage cta={GoBackButton} />
   * ```
   */
  cta?: React.FC<{ ctx: ErrorPageContext }>
}

export interface CustomErrorStyleProps {
  contentWidth?: number | string
}

const useStyles = makeStyles<Theme, CustomErrorStyleProps>((theme) => ({
  root: {
    "&&": {
      margin: theme.spacing(4, "auto"),
    },
  },
  content: {
    maxWidth: ({ contentWidth = 608 }) => contentWidth,
    margin: "0 auto",
  },
  errorContent: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
  },
  imgWrapper: {
    margin: "0 auto",
    textAlign: "center",
  },
}))

/**
 * A generic component for error pages. See `image`, `title`, `description` and `cta` props for more details.
 *
 * There are also two pre-defined error pages: `Error404Page` and `Error500Page`, which you can use as base to extend
 * or override the default behavior.
 */
export const CustomErrorPage: React.FC<CustomErrorPageProps> = (props) => {
  const { className, image, title, description, cta, ...rest } = props
  const classes = useStyles(props)
  const { t } = useTranslation()

  return (
    <PageContentBox className={clsx(className, classes.root)} {...rest}>
      <Grid className={classes.content} container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <div className={classes.imgWrapper}>{typeof image === "string" ? <img src={image} /> : image}</div>
        </Grid>
        <Grid item xs={12}>
          <Typography size={32} weight={600} component="h1" align="center">
            {typeof title === "string" ? t(title) : title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {typeof description === "string" ? (
            <Trans
              i18nKey={description}
              components={{
                p: <Typography size={18} align="center" component="p" className={classes.errorContent} />,
              }}
            />
          ) : (
            <Typography size={18} align="center" component="p">
              {description}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <ErrorPageContext.Consumer>
            {(ctx) => (
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button color="default" variant="outlined" href={Routes.Support} onClick={ctx.clearError}>
                    {t("error_pages.common.contact_support")}
                  </Button>
                </Grid>
                {cta ? <Grid item>{cta({ ctx })}</Grid> : null}
              </Grid>
            )}
          </ErrorPageContext.Consumer>
        </Grid>
      </Grid>
    </PageContentBox>
  )
}

/**
 * A generic component for error dialogs. See `title`, `description` and `cta` props for more details.
 *
 * There are also two pre-defined error dialogs: `Error404Dialog` and `Error500Dialog`, which you can use as base to
 * extend or override the default behavior.
 */
export const CustomErrorDialog: React.FC<CustomErrorDialogProps> = (props) => {
  const { className, title, description, cta, ...rest } = props
  const classes = useStyles(props)
  const { t } = useTranslation()

  return (
    <ErrorDialog
      title={typeof title === "string" ? t(title) : title}
      className={clsx(className, classes.root)}
      dialogActions={
        <>
          <ErrorPageContext.Consumer>
            {(ctx) => (
              <Button color="default" variant="outlined" href={Routes.Support} onClick={ctx.clearError}>
                {t("error_pages.common.contact_support")}
              </Button>
            )}
          </ErrorPageContext.Consumer>
          {cta ? <ErrorPageContext.Consumer>{(ctx) => cta({ ctx })}</ErrorPageContext.Consumer> : null}
        </>
      }
      {...rest}
    >
      {typeof description === "string" ? (
        <Trans
          i18nKey={description}
          components={{
            p: <Typography align="center" component="p" className={classes.errorContent} />,
          }}
        />
      ) : (
        <Typography align="center" component="p">
          {description}
        </Typography>
      )}
    </ErrorDialog>
  )
}
