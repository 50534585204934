import React, { useState } from "react"
import { Feedback, FeedbackStatus, Insight, InsightValueType, parsePossibleValues } from "@planckdata/api"
import {
  alpha,
  Button,
  colors,
  CustomComponentProps,
  EventHandler,
  Grid,
  GridProps,
  makeStyles,
  Select,
  MenuItem,
  TextField,
  takeInputValue,
} from "@planckdata/react-components"
import { useTranslation } from "i18n"
import { FeedbackArguments } from "../../../models"

export interface InsightOption {
  text: string
  value: string
}

export interface EditFeedbackProps extends CustomComponentProps<GridProps> {
  feedback?: Feedback
  insight: Insight
  onClose: () => void
  onFeedbackSubmit: (args: FeedbackArguments) => Promise<Feedback>
}

const useStyles = makeStyles((theme) => ({
  TextArea: {
    padding: "5px 5px 5px 10px",
    width: "100%",
    background: colors.menuItemHighlight,
    borderRadius: 8,
  },
  Button: {
    paddingRight: 25,
    paddingLeft: 25,
  },
  selectContainer: {
    marginBottom: theme.spacing(2),
  },
  fixedValueSelect: {
    border: `1px solid ${alpha(colors.basicBorderColor, 0.2)}`,
    borderRadius: 8,
  },
  clearSelect: {
    "&& .MuiSvgIcon-root": {
      fontSize: 16,
    },
  },
}))

enum FormState {
  Initial,
  Submitting,
  Submitted,
}

export const EditFeedback: React.FC<EditFeedbackProps> = ({
  feedback,
  onFeedbackSubmit,
  onClose,
  insight,
  ...props
}) => {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const [comment, setComment] = useState(feedback?.comment ?? "")
  const [currentAction, setCurrentAction] = useState<FormState>(FormState.Initial)

  const availableInsightOptions = getInsightOptions(insight)
  // const foundValueOption =
  //   availableInsightOptions?.find((o) => feedback?.fixed_value?.toLowerCase() === o.value.toLowerCase()) ??
  //   availableInsightOptions?.find((o) => insight.value?.toLowerCase() === o.value.toLowerCase())

  const [customFixedValue, setCustomFixedValue] = useState(feedback?.fixed_value ?? "")

  async function submitFeedback() {
    try {
      setCurrentAction(FormState.Submitting)
      await onFeedbackSubmit({ status: FeedbackStatus.disagree, fixedValue: customFixedValue, comment })
    } finally {
      setCurrentAction(FormState.Submitted)
    }
  }

  function parseNumeric(e: React.ChangeEvent<HTMLInputElement>) {
    if (insight.expected_value_type === InsightValueType.DoubleAttribute) {
      setCustomFixedValue(e.target.value)
      return
    }
    if (e.target.value.indexOf(".") > -1) {
      setCustomFixedValue(e.target.value.substring(0, e.target.value.indexOf(".")))
      return
    }
    setCustomFixedValue(e.target.value)
  }

  return (
    <form onSubmit={EventHandler.prevent(submitFeedback)}>
      {availableInsightOptions?.length ? (
        <div className={classes.selectContainer}>
          <Select
            className={classes.fixedValueSelect}
            value={customFixedValue}
            onChange={(e) => setCustomFixedValue(e.target.value as string)}
            fullWidth
            displayEmpty
          >
            <MenuItem value="">{t("single_business_page.insights.feedback.fixed_value_select_placeholder")}</MenuItem>
            {availableInsightOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </div>
      ) : isTextFieldInsight(insight) ? (
        <div className={classes.selectContainer}>
          <TextField
            value={customFixedValue}
            label={t("single_business_page.insights.feedback.fixed_value_text_placeholder")}
            InputLabelProps={{
              shrink: Boolean(customFixedValue.length),
            }}
            inputProps={{
              onChange: isNumericInsight(insight) ? parseNumeric : takeInputValue(setCustomFixedValue),
            }}
            fullWidth
            type={isNumericInsight(insight) ? "number" : "text"}
          />
        </div>
      ) : null}
      <Grid container>
        <Grid item xs={12}>
          <TextField
            placeholder={t("single_business_page.insights.feedback.placeholder")}
            multiline
            rows={8}
            disabled={currentAction === FormState.Submitting}
            value={comment}
            onChange={(e) => setComment(e.currentTarget.value)}
            className={classes.TextArea}
            InputProps={{ disableUnderline: true }}
            inputProps={{ "data-testid": "EditFeedbackInput" }}
          />
        </Grid>
        <Grid container spacing={1} justifyContent="flex-end" style={{ paddingTop: 16 }}>
          <Grid item>
            <Button variant="outlined" color="link" className={classes.Button} onClick={onClose}>
              {t("single_business_page.insights.feedback.cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.Button}
              disableElevation
              type="submit"
              data-testid="SubmitDisagreeFeedbackButton"
              disabled={currentAction === FormState.Submitting}
            >
              {t("single_business_page.insights.feedback.submit")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

function getInsightOptions(insight: Insight): InsightOption[] {
  if (!isSelectInsight(insight)) {
    return []
  }
  return parsePossibleValues(insight.possible_values, insight.value).map((s) => ({ text: s, value: s }))
}

function isSelectInsight(insight: Insight): boolean {
  return [InsightValueType.BooleanAttribute, InsightValueType.RangeAttribute].includes(insight.expected_value_type)
}

function isTextFieldInsight(insight: Insight): boolean {
  return [
    InsightValueType.StringAttribute,
    InsightValueType.DoubleAttribute,
    InsightValueType.IntegerAttribute,
  ].includes(insight.expected_value_type)
}

function isNumericInsight(insight: Insight): boolean {
  return [InsightValueType.DoubleAttribute, InsightValueType.IntegerAttribute].includes(insight.expected_value_type)
}
