import React from "react"
import { BooleanValueSwitch, ValueSwitchFn } from "@planckdata/react-components"
import { PWAUser, PWAUserSettings, useUser } from "user-context"
import { useLocalStorage } from "@planckdata/typescript-utils/storage_utils"
import { StorageNS } from "storageKeys"

export interface FeatureFlagSwitchProps {
  /** Feature flag name to inspect, or function given the user and returning `boolean` check. */
  flag: keyof PWAUser["flags"]
  /** What to render when the feature flag is active (`true`) */
  renderActive: ValueSwitchFn<boolean>
  /** What to render when the feature flag is inactive (`false`) */
  renderInactive: ValueSwitchFn<boolean>
}

export interface UserSettingSwitchProps {
  /** Setting name to inspect, or function given the user and returning `boolean` check. */
  setting: keyof PWAUserSettings | ((user: PWAUser) => any)
  /** What to render when the setting is active (`true`) */
  renderActive: ValueSwitchFn<boolean>
  /** What to render when the setting is inactive (`false`) */
  renderInactive: ValueSwitchFn<boolean>
}

/** flags that may be overridden by local storage */
const CAN_OVERRIDE_FLAGS: Array<FeatureFlagSwitchProps["flag"]> = ["newSingleBusinessPage"]

/**
 * Render an active/inactive (boolean) state based on a feature flag
 */
export const FeatureFlagSwitch: React.FC<FeatureFlagSwitchProps> = ({ flag, renderActive, renderInactive }) => {
  const { current: user } = useUser()
  const local = useLocalStorage<boolean>(StorageNS.FLAGS, flag)

  const canOverrideFlag = CAN_OVERRIDE_FLAGS.includes(flag)
  const overrideValue = local.get()
  const flagOverrideExists = overrideValue !== undefined
  const userFlagValue = Boolean(user?.flags[flag])
  const isFeatureEnabled = Boolean(canOverrideFlag && flagOverrideExists ? overrideValue : userFlagValue)

  return <BooleanValueSwitch value={isFeatureEnabled} renderTrue={renderActive} renderFalse={renderInactive} />
}

/**
 * Render an active/inactive (boolean) state based on a user setting
 */
export const UserSettingSwitch: React.FC<UserSettingSwitchProps> = ({ setting, renderActive, renderInactive }) => {
  const { current: user } = useUser()
  const _setting = !user ? false : typeof setting === "function" ? setting(user!) : user?.userSettings?.[setting]
  const isSettingEnabled = Boolean(_setting)

  return <BooleanValueSwitch value={isSettingEnabled} renderTrue={renderActive} renderFalse={renderInactive} />
}

export default FeatureFlagSwitch
