import { alpha, colors, makeStyles, typography } from "@planckdata/react-components"

export const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid transparent",
    borderRadius: 8,
    background: "transparent",
    "& .MuiSelect-select": {
      background: "transparent",
    },
  },
  row: {
    margin: theme.spacing(1, 0),
    display: "flex",
    alignItems: "center",
    "&:first-child": {
      marginTop: 0,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  select: {
    paddingRight: theme.spacing(1),
  },
  text: {
    padding: theme.spacing(0.5, 1),
    "& ::-webkit-input-placeholder": {
      fontStyle: "italic",
      opacity: 0.7,
    },
  },
  removedIndicator: {
    fontStyle: "italic",
    opacity: 0.7,
  },
  hover: {
    borderColor: alpha(colors.basicBorderColor, 0.2),
  },
  focused: {
    boxShadow: `0px 2px 16px 0px #97979714`,
    borderColor: alpha(colors.basicBorderColor, 0.2),
    background: "#fff",
  },
  table: {
    "& .MuiTableRow-root": {
      marginBottom: 8,
      alignItems: "baseline",
      flexWrap: "nowrap",
      marginTop: 0,
      "&:last-child": {
        marginBottom: 0,
      },
    },
  },
  tableCell: {
    padding: 4,
    border: "none",
    ...typography({}),
  },

  index: {
    ...typography({}),
  },
  objectListLabel: {
    top: 4,
    left: 4,
  },
}))
