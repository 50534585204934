import { useUser } from "user-context"

export function useHideUsername(username: string): boolean {
  const user = useUser()
  if (!user.current?.flags.anonymousState) {
    return false
  }

  return !user.current || user.current.username !== username
}

export default useHideUsername
