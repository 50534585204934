import React, { useCallback, useEffect, useState } from "react"
import { useLocalStorage } from "@planckdata/typescript-utils/storage_utils"
import { StorageNS } from "storageKeys"

interface InsightsMappingTourWrapperProps {
  render(state: boolean): React.ReactNode
}

export const InsightsMappingTourWrapper: React.FC<InsightsMappingTourWrapperProps> = (props) => {
  const insightsMappingTourDismissedStorage = useLocalStorage<boolean>(StorageNS.INSIGHTS_MAPPING_TOUR, "dismissed")
  const [insightsMappingTourDismissed, setInsightsMappingTourDismissed] = useState(
    insightsMappingTourDismissedStorage.get(),
  )

  const insightsMappingTourDismissedCB = useCallback(() => {
    if (insightsMappingTourDismissedStorage.get() !== insightsMappingTourDismissed) {
      setInsightsMappingTourDismissed(insightsMappingTourDismissedStorage.get())
    }
  }, [insightsMappingTourDismissed])

  useEffect(() => {
    if (insightsMappingTourDismissed) return

    const interval = setInterval(insightsMappingTourDismissedCB, 1000)
    return () => clearInterval(interval)
  }, [insightsMappingTourDismissedCB, insightsMappingTourDismissed])

  return props.render(insightsMappingTourDismissed ?? false) as JSX.Element
}

export default InsightsMappingTourWrapper
