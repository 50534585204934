import React from "react"
import clsx from "clsx"
import {
  AddIcon,
  Button,
  ButtonProps,
  CrossIcon,
  IconButton,
  IconButtonProps,
  makeStyles,
  TrashIcon,
} from "@planckdata/react-components"
import { useTranslation } from "../../../i18n"

export interface EditIconButtonProps {
  visible?: boolean
}

export const useStyles = makeStyles((theme) => ({
  addNewButton: {
    opacity: 0,
  },
  editIconButton: {
    width: 16,
    height: 16,
    opacity: 0,
    pointerEvents: "none",
  },
  icon: {
    width: 16,
    height: 16,
  },
  clearValueButton: {
    marginLeft: theme.spacing(1),
  },
  removeItemButton: {
    margin: theme.spacing(0, 0.5),
  },
  visible: {
    opacity: 1,
    pointerEvents: "all",
  },
}))

export const ClearValueButton: React.FC<EditIconButtonProps & IconButtonProps> = ({ visible, className, ...props }) => {
  const classes = useStyles()
  return (
    <IconButton
      className={clsx(className, classes.editIconButton, classes.clearValueButton, {
        [classes.visible]: visible,
      })}
      {...props}
    >
      <CrossIcon className={classes.icon} color="disabled" />
    </IconButton>
  )
}

export const RemoveItemButton: React.FC<EditIconButtonProps & IconButtonProps> = ({ visible, className, ...props }) => {
  const classes = useStyles()
  return (
    <IconButton
      className={clsx(className, classes.editIconButton, classes.removeItemButton, {
        [classes.visible]: visible,
      })}
      {...props}
    >
      <TrashIcon className={classes.icon} color="disabled" />
    </IconButton>
  )
}

export const AddNewButton: React.FC<EditIconButtonProps & ButtonProps> = ({ className, visible, ...props }) => {
  const classes = useStyles(props)
  const { t } = useTranslation()

  return (
    <Button
      variant="text"
      startIcon={<AddIcon />}
      className={clsx(classes.addNewButton, className, { [classes.visible]: visible })}
      {...props}
    >
      {t("general.add_new")}
    </Button>
  )
}
