import React, { useEffect, useState } from "react"
import clsx from "clsx"
import uniqBy from "lodash/uniqBy"
import { VALUE_ESCAPE } from "keycode-js"
import { Table, TableHead, TableBody, TableRow, TableCell, TextField } from "@planckdata/react-components"
import { InsightValue } from "@planckdata/api"
import { useTranslation } from "i18n"
import { useStyles } from "./Styles.hook"
import { ClearValueButton, AddNewButton } from "./buttons"
import { buildInsight } from "utils"

export type FormatValueFunction<T> = (value?: T) => string | undefined

export interface InsightTableHeader {
  key: keyof InsightValue
  title?: string
  formatFn?: FormatValueFunction<any>
}

export interface TableHoverEditProps {
  headers: Array<InsightTableHeader>
  value: Array<InsightValue>
  onChange: (value: Array<InsightValue>) => void
}

export const TableHoverEdit: React.FC<TableHoverEditProps> = ({ headers, value, onChange, ...props }) => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const [hover, setHover] = useState(false)
  const [focused, setFocused] = useState(false)
  const [editedValues, setEditedValues] = useState([...value])

  useEffect(() => {
    setEditedValues([...value])
  }, [value])

  function handleBlur(index: number, newValue: string) {
    if (!newValue.trim()) {
      handleRemoveItem(index)
    } else if (newValue.trim() && editedValues[index].value !== newValue) {
      editedValues[index] = { ...editedValues[index], value: newValue }
      const normalized = normalizeValues()
      setEditedValues(normalized)
      onChange(normalized)
    }
    setFocused(false)
  }

  function handleRemoveItem(index: number) {
    editedValues.splice(index, 1)
    const normalized = normalizeValues()
    setEditedValues(normalized)
    onChange(normalized)
  }

  function handleKeyUp(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === VALUE_ESCAPE) {
      setFocused(false)
      onChange(value)
    }
  }

  function addItem() {
    setEditedValues([...editedValues, buildInsight({})])
  }

  function normalizeValues() {
    return uniqBy(
      editedValues.filter((p) => p.value.trim()),
      (p) => p.value.trim(),
    ).filter((p) => p.value.trim())
  }

  function renderInput(index: number, value: any) {
    return (
      <TextField
        className={clsx(classes.root, classes.text, {
          [classes.hover]: hover,
          [classes.focused]: focused,
        })}
        onFocus={() => setFocused(true)}
        onBlur={(e) => handleBlur(index, e.target.value)}
        onKeyUp={handleKeyUp}
        variant="standard"
        InputProps={{
          disableUnderline: true,
        }}
        defaultValue={value}
      />
    )
  }

  function getFormattedValue(header: InsightTableHeader, value: InsightValue) {
    const val = value[header.key]
    return <>{header.formatFn ? header.formatFn(val) : val}</>
  }

  const tableHeaders = React.useMemo(
    () =>
      editedValues.length === 0
        ? null
        : headers.map((header, i) => {
            return (
              <TableCell key={i} className={classes.tableCell} style={{ paddingLeft: i === 0 ? 12 : undefined }}>
                {header.title}
              </TableCell>
            )
          }),
    [headers, editedValues, classes.tableCell],
  )

  const tableRows =
    editedValues.length === 0
      ? renderRemoved()
      : editedValues.map((val, i) => {
          return (
            <TableRow key={`${i}/${val.value}`}>
              {headers.map((header, columnIndex) => (
                <TableCell key={header.key} className={classes.tableCell}>
                  {columnIndex === 0 ? renderInput(i, val[header.key]) : getFormattedValue(header, val)}
                </TableCell>
              ))}
              <TableCell className={classes.tableCell}>
                {<ClearValueButton onClick={() => handleRemoveItem(i)} visible={hover || focused} />}
              </TableCell>
            </TableRow>
          )
        })

  function renderRemoved() {
    return <div className={classes.removedIndicator}>({t("single_business_page.insights.value_removed")})</div>
  }

  return (
    <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {tableHeaders}
            <TableCell className={classes.tableCell} />
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows}
          <TableRow>
            <TableCell className={classes.tableCell}>
              <AddNewButton onClick={() => addItem()} visible={hover || focused} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}
