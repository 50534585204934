import { LoginForm, LoginFormMode, makeStyles } from "@planckdata/react-components"
import AuthLayout from "components/layouts/AuthLayout"
import ClarityConsentDialog, { useClarityConsent } from "components/molecules/ClarityConsentDialog"
import { usePageView } from "hooks/PageView.hook"
import React, { Suspense } from "react"
import { Route, Switch } from "react-router"
import { Routes } from "routes"
import AppLoader from "../AppLoader"
import { useAppRouter } from "./AppRouter.hook"
import MainLayoutRoutes from "./MainLayoutRoutes"
import PrivateRoute from "./PrivateRoute"

export interface AppRouterProps {}

const useStyles = makeStyles({
  updatePasswordFormContainer: {
    paddingLeft: 64,
    paddingRight: 64,
  },
})

const AppRouter: React.FC<AppRouterProps> = () => {
  const { loginProps } = useAppRouter()
  const classes = useStyles()
  const { dialogVisible } = useClarityConsent()
  usePageView()

  const TermsAndConditions = React.lazy(() => import("pages/TermsAndConditions"))
  const ForgotPasswordPage = React.lazy(() => import("pages/Password/ForgotPassword/ForgotPasswordPage"))
  const UpdatePasswordPage = React.lazy(() => import("pages/Password/UpdatePassword/UpdatePasswordPage"))

  return (
    <Suspense fallback={<AppLoader />}>
      <Switch>
        {/* Login routes - need different layout */}
        <Route
          path={Routes.Login}
          exact
          render={() => (
            <AuthLayout>
              <LoginForm {...loginProps} />
            </AuthLayout>
          )}
        />
        <Route
          path={Routes.Register}
          exact
          render={() => (
            <AuthLayout>
              <LoginForm {...loginProps} mode={LoginFormMode.Register} />
            </AuthLayout>
          )}
        />
        <Route
          path={Routes.ForgotPassword}
          render={() => (
            <AuthLayout>
              <ForgotPasswordPage />
            </AuthLayout>
          )}
        />
        <Route
          path={Routes.UpdatePassword}
          render={() => (
            <AuthLayout className={classes.updatePasswordFormContainer}>
              <UpdatePasswordPage />
            </AuthLayout>
          )}
        />
        <PrivateRoute path={Routes.TermsAndConditions} render={() => <TermsAndConditions />} />
        {/* Main routes - need main layout */}
        <Route
          path="*"
          render={() => {
            return <MainLayoutRoutes />
          }}
        />
      </Switch>
      {dialogVisible && <ClarityConsentDialog />}
    </Suspense>
  )
}

export default AppRouter
