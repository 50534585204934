import { createAnalyticsDispatch, createInitAnalyticsUser, initAnalytics } from "@planckdata/react-components"
import { ENV } from "env"
import _mixpanel from "mixpanel-browser"

export const mixpanel = initAnalytics(_mixpanel, "umbrella", ENV.MIXPANEL_API_KEY)

export const dispatchTrackEvent = createAnalyticsDispatch(mixpanel, "umbrella")

export const initAnalyticsUser = createInitAnalyticsUser(mixpanel, "umbrella")

export function mixpanelIdentify(username: string): void {
  const distinctId = mixpanel.get_distinct_id()
  if (distinctId !== username) {
    mixpanel.alias(distinctId, username)
  }
  mixpanel.identify(username)
}
