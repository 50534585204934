import React from "react"
import {
  CustomComponentProps,
  Grid,
  GridProps,
  OverflowTooltip,
  Typography,
  TypographyProps,
  makeStyles,
  colors,
} from "@planckdata/react-components"

export enum IconLevel {
  Warning,
  Error,
  Disabled,
  Success,
}

// TODO deprecate [children] and use [icon] instead
export interface TextIconContainerProps extends CustomComponentProps<GridProps> {
  text?: React.ReactNode
  textTruncate?: boolean
  level?: IconLevel
  typographyProps?: TypographyProps
  iconPlacing?: string
  applyTextColor?: boolean
  displayIcon?: boolean
}

const useStyles = makeStyles({
  warningIconContainer: {
    opacity: 0.8,
    color: colors.warning,
  },
  errorIconContainer: {
    opacity: 0.8,
    color: colors.error,
  },
  successIconContainer: {
    opacity: 0.8,
    color: colors.success,
  },
  warningTextContainer: {
    color: colors.warning,
  },
  errorTextContainer: {
    color: colors.error,
  },
  successTextContainer: {
    color: colors.success,
  },
  basicContainer: {
    color: `${colors.foregroundAltText}`,
  },
})

export const TextIconContainer: React.FC<TextIconContainerProps> = ({
  applyTextColor,
  displayIcon = true,
  iconPlacing,
  level,
  textTruncate,
  typographyProps = {},
  children,
  ...props
}) => {
  const text = props.text as string
  const textGridProps: GridProps = {}
  const classes = useStyles()

  const iconClass = React.useMemo(() => {
    switch (level) {
      case IconLevel.Warning:
        return classes.warningIconContainer
      case IconLevel.Error:
        return classes.errorIconContainer
      case IconLevel.Disabled:
        return classes.basicContainer
      case IconLevel.Success:
        return classes.successIconContainer
      default:
        return classes.basicContainer
    }
  }, [
    classes.basicContainer,
    classes.errorIconContainer,
    classes.successIconContainer,
    classes.warningIconContainer,
    level,
  ])

  const textClass = React.useMemo(() => {
    if (!applyTextColor) {
      return undefined
    }
    switch (level) {
      case IconLevel.Warning:
        return classes.warningTextContainer
      case IconLevel.Error:
        return classes.errorTextContainer
      case IconLevel.Disabled:
        return classes.basicContainer
      case IconLevel.Success:
        return classes.successTextContainer
      default:
        return classes.basicContainer
    }
  }, [
    applyTextColor,
    classes.basicContainer,
    classes.errorTextContainer,
    classes.successTextContainer,
    classes.warningTextContainer,
    level,
  ])

  typographyProps = { ...typographyProps }

  if (textTruncate) {
    textGridProps.xs = displayIcon ? 11 : 12
    typographyProps.noWrap = true
  }

  return (
    <Grid container spacing={1} wrap="nowrap" {...props}>
      {displayIcon ? (
        <Grid item style={{ display: "flex", placeItems: iconPlacing }} className={iconClass}>
          {children}
        </Grid>
      ) : null}
      <Grid item {...textGridProps}>
        <OverflowTooltip title={text}>
          <Typography {...typographyProps} className={textClass}>
            {text}
          </Typography>
        </OverflowTooltip>
      </Grid>
    </Grid>
  )
}

TextIconContainer.defaultProps = {
  typographyProps: { size: 16 },
  iconPlacing: "center",
}
