import { CaseData, CaseRun } from "@planckdata/api"

const EVIDENCE_STORE_KEY = "PLANCK_EVIDENCE_SEEN"
const DAYS_SINCE_CREATION_FOR_NEW_COUNT = 7
const DAY_IN_MILLIS = 1000 * 60 * 60 * 24

/** TODO: use mobx? */
export class SeenEvidenceStore {
  static getOrCreateStore(): { [key: string]: number } {
    const evidenceStore = localStorage.getItem(EVIDENCE_STORE_KEY)
    if (!evidenceStore) {
      return {}
    }
    return JSON.parse(evidenceStore)
  }

  static save(evidenceStore: { [key: string]: number }): void {
    localStorage.setItem(EVIDENCE_STORE_KEY, JSON.stringify(evidenceStore))
  }

  static setEvidenceSeen(caseId: string, insightName: string): void {
    const store = SeenEvidenceStore.getOrCreateStore()
    store[caseId + insightName] = 1
    SeenEvidenceStore.save(store)
  }

  static getEvidenceSeen(caseId: string, insightName: string): boolean {
    const store = SeenEvidenceStore.getOrCreateStore()
    return !!store[caseId + insightName]
  }

  static isNewEvidence(rawCreatedDate: Date): boolean {
    const createdDate = new Date(rawCreatedDate)
    const millisFromCreation = new Date().getTime() - createdDate.getTime()
    const daysFromCreation = Math.ceil(millisFromCreation / DAY_IN_MILLIS)
    return daysFromCreation <= DAYS_SINCE_CREATION_FOR_NEW_COUNT
  }

  static getNewEvidenceCount(caseData: CaseData): number {
    const insightsWithNewEvidence = caseData.insights.filter(
      (ins) => !!ins.evidence && SeenEvidenceStore.isNewEvidence(ins.evidence.created_date),
    )
    const insightsWithUnseenNewEvidence = insightsWithNewEvidence.filter(
      (ins) => !SeenEvidenceStore.getEvidenceSeen(caseData.case_id, ins.name),
    )
    return insightsWithUnseenNewEvidence.length
  }

  static getNewEvidenceCountFromRun(caseRunData: CaseRun): number {
    const insightsWithUnseenNewEvidence = Object.entries(caseRunData.evidence_summary)
      .filter((evidence) => SeenEvidenceStore.isNewEvidence(evidence[1]))
      .filter((evidence) => !SeenEvidenceStore.getEvidenceSeen(caseRunData.id, evidence[0]))
    return insightsWithUnseenNewEvidence.length
  }
}
