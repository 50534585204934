import { useEffect, useState } from "react"

export interface ArrayEdit {
  values: Array<string>
  update: (index: number, newValue: string) => void
  removeItem: (index: number) => void
  addItem: (value?: string) => void
}

export function useArrayEdit(value: Array<string>): ArrayEdit {
  const [values, setValues] = useState(value)

  useEffect(() => {
    setValues(value)
  }, [value])

  function update(index: number, newValue: string) {
    values[index] = newValue
    setValues(values)
  }

  function removeItem(index: number) {
    values.splice(index, 1)
    setValues(values.slice())
  }

  function addItem(value = "") {
    setValues([...values, value])
  }

  return {
    values,
    update,
    removeItem,
    addItem,
  }
}
