import "env"
import { initI18n } from "i18n"
import { GlobalStyle } from "@planckdata/react-components"
import AppLoader from "pages/App/AppLoader"

import React from "react"
import ReactDOM from "react-dom"
import App from "./pages/App"

initI18n(["pwa", "react-components"], ["en-US", "de-DE"])

ReactDOM.render(
  // TODO: removed until @material-ui supports Strict Mode (v5)
  // <React.StrictMode>
  <React.Suspense fallback={<AppLoader />}>
    <GlobalStyle loadFonts={false} />
    <App />
  </React.Suspense>,
  // </React.StrictMode>,
  document.getElementById("root"),
)
