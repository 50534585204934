import React, { ReactElement } from "react"
import { ExportDialog, ExportFileFormat } from "./ExportDialog"
import { ExportButton } from "./ExportButton"
import useTrackEvents from "hooks/track-events.hook"

export interface InsightsExportOptions {
  format: "xlsx" | "pdf"
  hide_empty_values: boolean
  feedback: boolean
}

export interface ExportComponentProps {
  exportFunction: (options: InsightsExportOptions) => void
  beforeExportText?: ReactElement<string> | string
  beforeExportTitle?: ReactElement<string> | string
  afterExportTitle?: ReactElement<string> | string
  afterExportText?: ReactElement<string> | string
  afterExportButtonText?: ReactElement<string> | string
  beforeExportButtonText?: ReactElement<string> | string
  disabled?: boolean
  renderExportWithFeedback?: boolean
  exportFormats?: ExportFileFormat[]
}

export const ExportComponent: React.FC<ExportComponentProps> = ({
  exportFunction,
  beforeExportText,
  beforeExportTitle,
  afterExportTitle,
  afterExportText,
  afterExportButtonText,
  beforeExportButtonText,
  disabled,
  exportFormats,
  renderExportWithFeedback,
}) => {
  const [openExportDialog, setOpenExportDialog] = React.useState(false)
  const { trackExportButtonClicked } = useTrackEvents()

  function handleClick() {
    setOpenExportDialog(true)
    trackExportButtonClicked()
  }

  return (
    <>
      <ExportButton onClick={() => handleClick()} disabled={disabled} />
      <ExportDialog
        exportFunction={exportFunction}
        open={openExportDialog}
        onClose={() => setOpenExportDialog(false)}
        beforeExportText={beforeExportText}
        beforeExportTitle={beforeExportTitle}
        afterExportTitle={afterExportTitle}
        afterExportText={afterExportText}
        afterExportButtonText={afterExportButtonText}
        beforeExportButtonText={beforeExportButtonText}
        exportFormats={exportFormats}
        renderExportWithFeedback={renderExportWithFeedback}
      />
    </>
  )
}

ExportComponent.defaultProps = {
  disabled: false,
  exportFormats: ["xlsx"],
}
