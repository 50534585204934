import React, { PropsWithChildren } from "react"
import { makeStyles, Theme, InformationPopover, InformationPopoverProps } from "@planckdata/react-components"

export interface InfoTooltipProps extends InformationPopoverProps {}

const useStyles = makeStyles<Theme, InfoTooltipProps>({
  infoIcon: {
    fontSize: 14,
  },
  infoIconContainer: {
    verticalAlign: "middle",
    marginLeft: 4,
    lineHeight: "1",
  },
})

export const InfoTooltip: React.FC<PropsWithChildren<InfoTooltipProps>> = (props) => {
  const classes = useStyles(props)

  return (
    <InformationPopover
      classes={{ root: classes.infoIconContainer, icon: classes.infoIcon }}
      iconProps={{
        containerProps: {
          size: 14,
        },
      }}
      {...props}
    />
  )
}

export default InfoTooltip
