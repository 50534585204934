export const InsightName = {
  BYOB: "BYOB",
  active_liquor_license: "ActiveLiquorLicense",
  adult_entertainment: "AdultEntertainment",
  aggravated_assault_score: "AggravatedAssaultScore",
  annual_payroll_buckets: "AnnualPayrollBuckets",
  annual_payroll_range: "AnnualPayrollRange",
  annual_revenue: "AnnualRevenue",
  arcade: "Arcade",
  bakery: "Bakery",
  banquet_hall: "BanquetHall",
  bar: "Bar",
  beer_garden: "BeerGarden",
  brew_pub: "BrewPub",
  building_footprint_sqft: "BuildingFootprintSqft",
  building_stories_count: "BuildingStoriesCount",
  burglary_score: "BurglaryScore",
  business_description: "BusinessDescription",
  business_description_gpt: "BusinessDescriptionGPT",
  business_keywords: "BusinessKeywords",
  business_owner_name: "BusinessOwnerName",
  business_square_feet: "BusinessSquareFeet",
  cafeteria: "Cafeteria",
  california_office_locations: "CaliforniaOfficeLocations",
  cannabis: "Cannabis",
  caterer: "Caterer",
  catering: "Catering",
  cocktail_bar: "CocktailBar",
  commercial_vehicles_amount: "CommercialVehiclesAmount",
  commercial_vehicles_types: "CommercialVehiclesTypes",
  construction_type: "ConstructionType",
  dancing: "Dancing",
  deep_frying: "DeepFrying",
  deliveries: "Deliveries",
  delivery_by_the_business: "DeliveryByTheBusiness",
  delivery_percent_range: "DeliveryPercentRange",
  diner: "Diner",
  distance_to_closest_building: "DistanceToClosestBuilding",
  distance_to_closest_fire_station: "DistanceToClosestFireStation",
  distance_to_closest_hospital: "DistanceToClosestHospital",
  distance_to_closest_police_station: "DistanceToClosestPoliceStation",
  distance_to_nearest_beach_miles: "DistanceToNearestBeachMiles",
  distance_to_nearest_coast_miles: "DistanceToNearestCoastMiles",
  dj: "DJ",
  doing_business_as: "DoingBusinessAs",
  doorman_bouncer: "DoormanBouncer",
  doughnut_shop: "DoughnutShop",
  effective_year_built: "EffectiveYearBuilt",
  email_address: "EmailAddress",
  employer_identification_number: "EmployerIdentificationNumber",
  entity_type: "EntityType",
  facebook_page: "FacebookPage",
  fine_dining: "FineDining",
  food_poisoning_index: "FoodPoisoningIndex",
  good_for_kids: "GoodForKids",
  happy_hour: "HappyHour",
  has_parking_lot: "HasParkingLot",
  has_swimming_pool: "HasSwimmingPool",
  heat_and_fire_index: "HeatAndFireIndex",
  hookah: "Hookah",
  in_business_since: "InBusinessSince",
  juice_shop: "JuiceShop",
  jukebox: "JukeBox",
  karaoke: "Karaoke",
  keywords: "Keywords",
  larceny_theft_score: "LarcenyTheftScore",
  latest_open_hour: "LatestOpenHour",
  legal_name: "LegalName",
  liquor: "Liquor",
  liquor_receipts_percent_range: "LiquorReceiptsPercentRange",
  live_entertainment: "LiveEntertainment",
  live_music: "LiveMusic",
  main_address: "MainAddress",
  main_website: "MainWebsite",
  mobile_food_business: "MobileFoodBusiness",
  multiple_entities: "MultipleEntities",
  naics_classification: "NAICSClassification",
  naics_classification_gpt_for_demo: "NAICSClassificationGPTForDemo",
  naics_classification_with_confidence: "NAICSClassificationWithConfidence",
  nightclub: "Nightclub",
  off_premise_catering: "OffPremiseCatering",
  open_24_7_hours: "Open247Hours",
  opening_24_7: "24HoursOpening",
  outdoor_seating: "OutdoorSeating",
  phone: "Phone",
  pizza_place: "PizzaPlace",
  pool_table: "PoolTable",
  premise_coordinates: "PremiseCoordinates",
  property_crime_score: "PropertyCrimeScore",
  restaurant_price_index: "RestaurantPriceIndex",
  restaurant_tenants: "RestaurantTenants",
  sandwich_shop: "SandwichShop",
  slip_fall_rbt_index: "SlipFallRBTIndex",
  social_rating: "SocialRating",
  sports_bar: "SportsBar",
  strip_club: "StripClub",
  subsidiaries_details: "SubsidiariesDetails",
  table_service: "TableService",
  tableside_cooking: "TableSideCooking",
  third_party_delivery: "ThirdPartyDelivery",
  total_employee_count: "TotalEmployeeCount",
  transporting_hazardous_materials: "TransportingHazardousMaterials",
  vehicle_exposure: "VehicleExposure",
  violent_crime_score: "ViolentCrimeScore",
  watercraft: "Watercraft",
  wine_bar: "WineBar",
  year_built: "YearBuilt",
} as const

export type InsightName = typeof InsightName[keyof typeof InsightName]
export default InsightName
