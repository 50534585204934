import React, { useState } from "react"
import clsx from "clsx"
import { Select, MenuItem, SelectProps, TextField } from "@planckdata/react-components"
import { useStyles } from "./Styles.hook"
import { useArrayEdit } from "./ArrayEdit.hook"
import { ClearValueButton, AddNewButton } from "./buttons"

export interface EnumListHoverEditProps extends Omit<SelectProps, "value" | "onChange"> {
  value: Array<string>
  options: Array<string>
  onChange: (value: Array<string>) => void
}

export const EnumListHoverEdit: React.FC<EnumListHoverEditProps> = ({ value, options, onChange, ...props }) => {
  const classes = useStyles(props)
  const [hover, setHover] = useState(false)
  const [focused, setFocused] = useState(false)
  const [showAddNewDropdown, setShowAddNewDropdown] = useState(false)
  const { values, update, addItem, removeItem } = useArrayEdit(value)
  const availableOptions = options.filter((option) => !values.some((val) => val === option))
  const caret = hover ? undefined : React.createElement("span").type

  function handleChange(index: number, newValue: string) {
    update(index, newValue)
    onChange(removeDuplicates())
    setFocused(false)
    setShowAddNewDropdown(false)
  }

  function handleRemoveItem(index: number) {
    if (index === values.length - 1) {
      setShowAddNewDropdown(false)
    }
    removeItem(index)
    onChange(values)
  }

  function handleAddItem() {
    if (availableOptions.length > 0) {
      addItem()
      setShowAddNewDropdown(true)
    }
  }

  function removeDuplicates() {
    return [...new Set(values)]
  }

  function renderDropdown() {
    const index = values.length - 1
    return (
      <div className={classes.row}>
        <Select
          fullWidth
          {...props}
          value={""}
          className={clsx(classes.root, classes.select, {
            [classes.hover]: hover,
            [classes.focused]: focused,
          })}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={(e) => handleChange(index, e.target.value as string)}
          variant="standard"
          IconComponent={caret}
        >
          {availableOptions.map((v) => (
            <MenuItem key={v} value={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
        <div>
          <ClearValueButton onClick={() => handleRemoveItem(index)} visible={hover || focused} />
        </div>
      </div>
    )
  }

  return (
    <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} unselectable="on">
      {values
        .filter((p) => p.trim())
        .map((val, i) => (
          <div className={classes.row} key={i}>
            <TextField
              className={clsx(classes.root, classes.text)}
              value={val}
              variant="standard"
              InputProps={{
                style: {
                  pointerEvents: "none",
                },
                disableUnderline: true,
              }}
            />
            <div>
              <ClearValueButton onClick={() => handleRemoveItem(i)} visible={hover || focused} />
            </div>
          </div>
        ))}
      {showAddNewDropdown && renderDropdown()}
      {availableOptions.length > 0 && !showAddNewDropdown && (
        <div className={classes.row}>
          <AddNewButton onClick={() => handleAddItem()} visible={hover || focused} />
        </div>
      )}
    </div>
  )
}
