import React, { useState } from "react"
import clsx from "clsx"
import { VALUE_ESCAPE } from "keycode-js"
import { RefOverflowTooltip, TextField, TextFieldProps } from "@planckdata/react-components"
import { useArrayEdit } from "./ArrayEdit.hook"
import { useStyles } from "./Styles.hook"
import { ClearValueButton, AddNewButton } from "./buttons"

export interface TextListHoverEditProps extends Omit<TextFieldProps, "value" | "onChange" | "inputRef"> {
  value: Array<string>
  onChange: (value: Array<string>) => void
}

export const TextListHoverEdit: React.FC<TextListHoverEditProps> = ({ value, onChange, ...props }) => {
  const classes = useStyles(props)
  const [hover, setHover] = useState(false)
  const [focused, setFocused] = useState(false)
  const { values, update, addItem, removeItem } = useArrayEdit(value)

  function handleChange(index: number, newValue: string) {
    if (newValue !== value[index]) {
      update(index, newValue)
      onChange(values)
    }
    setFocused(false)
    setHover(false)
  }

  function handleRemoveItem(index: number) {
    removeItem(index)
    onChange(values)
  }

  function handleKeyUp(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === VALUE_ESCAPE) {
      setFocused(false)
      setHover(false)
      onChange(value)
    }
  }

  return (
    <div onMouseMove={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {values.map((val, i) => (
        <ListInputItem
          key={`${i}/${val}`}
          value={val}
          onChange={(val) => handleChange(i, val)}
          onRemove={() => handleRemoveItem(i)}
          onFocus={() => setFocused(true)}
          onKeyUp={handleKeyUp}
          hover={hover}
          focused={focused}
        />
      ))}
      <div className={classes.row}>
        <AddNewButton onClick={() => addItem()} visible={hover || focused} />
      </div>
    </div>
  )
}

interface ListInputItemProps {
  value: string
  hover: boolean
  focused: boolean
  onChange: (value: string) => void
  onRemove: () => void
  onFocus: () => void
  onKeyUp: (event: React.KeyboardEvent<HTMLDivElement>) => void
}

const ListInputItem: React.FC<ListInputItemProps> = ({
  value,
  hover,
  focused,
  onChange,
  onRemove,
  onFocus,
  onKeyUp,
  ...props
}) => {
  const classes = useStyles(props)
  const inputRef = React.useRef<Element | null>(null)

  return (
    <div className={classes.row}>
      <RefOverflowTooltip elementRef={inputRef} title={value}>
        <div>
          <TextField
            fullWidth
            inputRef={inputRef}
            {...props}
            defaultValue={value}
            className={clsx(classes.root, classes.text, {
              [classes.hover]: hover,
              [classes.focused]: focused,
            })}
            onFocus={onFocus}
            onBlur={(e) => onChange(e.target.value)}
            onKeyUp={onKeyUp}
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
          />
        </div>
      </RefOverflowTooltip>
      <div>
        <ClearValueButton onClick={onRemove} visible={hover || focused} />
      </div>
    </div>
  )
}
