import React from "react"
import { Trans, useTranslation } from "../../../i18n"
import {
  alpha,
  Box,
  Button,
  colors,
  CustomComponentProps,
  FloatingActionsBar,
  FloatingActionsBarProps,
  LinkLike,
  makeStyles,
  Theme,
  Typography,
} from "@planckdata/react-components"
import clsx from "clsx"
import { useClarityConsent } from "./ClarityConsent.hook"

export interface ClarityConsentDialogProps
  extends CustomComponentProps<Omit<FloatingActionsBarProps, "open" | "label" | "actions">, keyof typeof useStyles>,
    ClarityConsentDialogStyleProps {
  //
}

export interface ClarityConsentDialogStyleProps {
  //
}

const useStyles = makeStyles<Theme, ClarityConsentDialogStyleProps>((theme) => ({
  root: {
    width: "calc(100vw - 64px)",
    maxWidth: 1280,
    alignItems: "flex-end",
    borderRadius: theme.spacing(3),
    marginRight: "auto",
    marginLeft: "auto",
  },
  overlay: {
    backgroundColor: alpha(colors.menuItemHighlight, 0.6),
  },
}))

export const ClarityConsentDialog: React.FC<ClarityConsentDialogProps> = (props) => {
  const { className, ...rest } = props
  const { t } = useTranslation()
  const classes = useStyles(props)
  const { updateConsent, dialogVisible } = useClarityConsent()
  const [open, setOpen] = React.useState(dialogVisible)
  const [expanded, setExpanded] = React.useState(false)
  const update = (consent: boolean) => {
    updateConsent(consent)
    setOpen(false)
  }

  if (!open) {
    return null
  }

  return (
    <Box width="100vw" height="100vh" className={classes.overlay} position="fixed" top={0} left={0} zIndex={9999}>
      <FloatingActionsBar
        className={clsx(className, classes.root)}
        open
        {...rest}
        label={
          <Typography color="foregroundText">
            {!expanded ? (
              <>
                <Trans i18nKey="clarity_consent.content_short" components={{ p: <p />, b: <b /> }} />{" "}
                <LinkLike onClick={() => setExpanded(true)}>
                  <Trans i18nKey="clarity_consent.read_more" components={{ p: <p />, b: <b /> }} />
                </LinkLike>
              </>
            ) : (
              <Trans i18nKey="clarity_consent.content_full" components={{ p: <p />, b: <b /> }} />
            )}
          </Typography>
        }
        actions={[
          <Button key="no" variant="contained" color="default" onClick={() => update(false)}>
            {t("clarity_consent.decline")}
          </Button>,
          <Box key={1} width={16} display="inline-block" />,
          <Button key="yes" variant="contained" color="primary" onClick={() => update(true)}>
            {t("clarity_consent.accept")}
          </Button>,
        ]}
      />
    </Box>
  )
}

export default ClarityConsentDialog
