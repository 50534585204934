import React, { useState } from "react"
import clsx from "clsx"
import {
  Collapse,
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  makeStyles,
  useTheme,
  colors,
  alpha,
  Theme,
  typography,
  CustomComponentProps,
} from "@planckdata/react-components"
import { CaretRightIcon } from "@planckdata/react-components/components/icons"
import ExpansionIcon from "@planckdata/react-components/mui/icons/ExpansionIcon"
import { useHistory, useLocation, useRouteMatch } from "react-router"
import { useTranslation } from "i18n"
import { useDrawerOpenWidth } from "./SideMenu"
import { PWAUser } from "user-context"

export interface SideMenuItemProps extends SideMenuItemData, CustomComponentProps<ListItemProps> {
  sideMenuOpen: boolean
  nesting?: number
  noWrap?: boolean
}

export interface SideMenuItemData extends CustomComponentProps<ListItemProps> {
  icon?: React.ReactNode
  text: React.ReactNode
  textSecondary?: React.ReactNode
  route: string
  allowMatch?(path: string): boolean
  exact?: boolean
  items?: Omit<SideMenuItemData, "sideMenuOpen">[]
  navigateOnClick?: boolean
  visible?: boolean | ((user: PWAUser | null) => boolean)
  trailingNode?: React.ReactNode
}

const useStyles = makeStyles<Theme, SideMenuItemProps>((theme) => ({
  normal: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: theme.spacing(6),
    borderLeft: `3px solid transparent`,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    height: "unset",
    "& .MuiListItemText-root > *": {
      fontSize: 14,
    },
  },
  selected: {
    // extra specificity => instead of `!important`
    "&&": {
      borderLeftColor: colors.primary,
      backgroundColor: "inherit",
    },
    "& .MuiListItemText-root > *": {
      fontWeight: 600,
    },
  },
  selectedNested: {
    // extra specificity => instead of `!important`
    "&&": {
      borderLeftColor: "transparent",
    },
  },
  icon: {
    color: alpha(colors.foregroundText, 0.4),
    transition: theme.transitions.create("min-width", {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
    "& .MuiSvgIcon-root": {
      fontSize: 24,
    },
  },
  iconSelected: {
    color: colors.primary,
  },
  iconOpen: {
    minWidth: theme.spacing(5),
  },
  iconNested: {
    minWidth: theme.spacing(2.5),
  },
  expandIcon: {
    fontSize: 14,
    color: alpha(colors.foregroundText, 0.4),
    verticalAlign: "middle",
    marginLeft: theme.spacing(1),
  },
  itemText: {
    display: "flex",
    alignItems: "center",
  },
  wrap: {
    whiteSpace: ({ noWrap }) => (noWrap ? undefined : "break-spaces"),
    overflow: ({ noWrap }) => (noWrap ? "hidden" : undefined),
    textOverflow: ({ noWrap }) => (noWrap ? "ellipsis" : undefined),
  },
  nowrap: {
    whiteSpace: "nowrap",
  },
  text: {},
  secondaryText: {
    ...typography({ size: 12, weight: 100 }),
  },
}))

export const SideMenuItem: React.FC<SideMenuItemProps> = (props) => {
  const {
    className,
    icon: _icon,
    text,
    textSecondary,
    route,
    sideMenuOpen,
    nesting = 0,
    trailingNode,
    allowMatch,
    exact,
    items = [],
    navigateOnClick = true,
    visible: _visible,
    noWrap: _noWrap,
    ...rest
  } = props
  const classes = useStyles(props)
  const matcher = useRouteMatch(route)
  const location = useLocation()

  const isSelected =
    // match is null, completely unrelated
    // top menu items need to match prefix, items can override match
    !nesting
      ? allowMatch?.(location.pathname) || (exact ? location.pathname === route : location.pathname.startsWith(route))
      : // sub items can exact match by default or override match with allowMatch
        matcher?.isExact || allowMatch?.(location.pathname)

  const [openSub, setOpenSub] = useState(isSelected)
  const [isHover, setIsHover] = useState(false)
  const history = useHistory()
  const { t } = useTranslation()
  const theme = useTheme()
  const drawerOpenWidth = useDrawerOpenWidth()

  const handleClick = () => {
    setOpenSub(!openSub)
    if (navigateOnClick) {
      history.push(route)
    }
  }
  const icon = nesting ? <CaretRightIcon htmlColor={isSelected ? colors.primary : undefined} /> : _icon

  const itemPaddingLeft = function () {
    if (nesting && (isSelected || isHover)) {
      return theme.spacing(nesting * 5.5)
    } else if (nesting) {
      return theme.spacing(nesting * 8.5)
    } else {
      return undefined
    }
  }

  const itemWidth = drawerOpenWidth - 64 - 16 * nesting - (nesting ? 0 : 40) + (trailingNode ? 40 : 0)

  React.useEffect(() => {
    if (!isSelected) {
      setOpenSub(false)
    }
  }, [isSelected, location.pathname])

  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        className={clsx(classes.normal, className, {
          [classes.nested]: nesting,
          [classes.selectedNested]: nesting && isSelected,
        })}
        classes={{ selected: classes.selected }}
        style={{
          paddingLeft: itemPaddingLeft(),
        }}
        selected={isSelected}
        onMouseOver={() => setIsHover(true)}
        onMouseOut={() => setIsHover(false)}
        {...(rest as any)}
      >
        {icon && (!nesting || (nesting && isHover) || isSelected) ? (
          <ListItemIcon
            className={clsx(classes.icon, {
              [classes.iconOpen]: sideMenuOpen,
              [classes.iconNested]: nesting,
              [classes.iconSelected]: isSelected,
            })}
          >
            {icon}
          </ListItemIcon>
        ) : null}
        <ListItemText
          className={classes.itemText}
          hidden={!sideMenuOpen}
          primary={
            <Fade in={sideMenuOpen}>
              <div className={clsx(classes.wrap, classes.text)} style={{ width: itemWidth }}>
                {typeof text === "string" ? t(text) : text}

                {trailingNode}

                {textSecondary ? <div className={classes.secondaryText}>{textSecondary}</div> : null}
              </div>
            </Fade>
          }
          secondary={
            sideMenuOpen && items.length > 0 ? (
              <Fade in={sideMenuOpen}>
                <span>
                  <ExpansionIcon expanded={openSub} className={classes.expandIcon} />
                </span>
              </Fade>
            ) : null
          }
        />
      </ListItem>

      {items.length > 0 && (
        <Collapse in={Boolean(openSub && sideMenuOpen)} timeout="auto">
          <List>
            {items.map(({ className: itemCls, ...item }, index) => {
              return (
                <SideMenuItem
                  className={clsx(itemCls, classes.nowrap)}
                  nesting={nesting + 1}
                  sideMenuOpen={sideMenuOpen}
                  key={index}
                  {...item}
                />
              )
            })}
          </List>
        </Collapse>
      )}
    </>
  )
}

export default SideMenuItem
