import React from "react"
import { EnumHoverEdit, EnumHoverEditProps } from "./EnumHoverEdit"
import { capitalize } from "@planckdata/react-components"

export interface BooleanHoverEditProps extends Omit<EnumHoverEditProps, "options"> {
  options: Array<string>
}

export const BooleanHoverEdit: React.FC<BooleanHoverEditProps> = ({ value, options, ...props }) => {
  const normalizedValue = capitalize(value)
  const normalizedOptions = options.map(capitalize)
  return <EnumHoverEdit {...props} value={normalizedValue} options={normalizedOptions} />
}
