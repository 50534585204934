import { colors, ElementComponentProps, alpha, makeStyles } from "@planckdata/react-components"
import React from "react"
import { CrossIcon } from "@planckdata/react-components/components/icons"
import cls from "clsx"

const useStyles = makeStyles({
  CrossIcon: {
    verticalAlign: "middle",
    fontSize: 11,
    display: "inline-block",
  },
  CrossContainer: {
    borderRadius: "50%",
    paddingTop: 3,
    lineHeight: 0,
    width: 17,
    height: 17,
  },
  ErrorColor: {
    color: colors.error,
    background: alpha(colors.error, 0.2),
  },
})

export const ErrorCross: React.FC<ElementComponentProps<HTMLDivElement>> = ({ className, ...props }) => {
  const classes = useStyles(props)

  return (
    <div {...(props as any)} className={cls(classes.CrossContainer, classes.ErrorColor, className)}>
      <CrossIcon className={classes.CrossIcon} />
    </div>
  )
}

ErrorCross.defaultProps = {
  disabled: false,
}
