import React from "react"
import cls from "clsx"
import { TextIconContainer, TextIconContainerProps } from "../../atoms/TextIconContainer"
import { useTranslation } from "i18n"
import {
  DbaIcon,
  IdIcon,
  ListIcon,
  LocationPinIcon,
  PhoneIcon,
  PlanckIconProps,
  PlanckIdIcon,
  UserIcon,
  WandIcon,
} from "@planckdata/react-components/components/icons"
import { CustomComponentProps, makeStyles } from "@planckdata/react-components"
import BusinessInsightsRiskIndicator, {
  BusinessInsightsRiskIndicatorProps,
} from "components/atoms/BusinessInsightsRiskIndicator"
import { CaseRun } from "@planckdata/api"

export interface TextIconProps extends CustomComponentProps<TextIconContainerProps> {
  value?: string | number
  iconProps?: PlanckIconProps
}

const useStyles = makeStyles({
  iconSize: {
    width: 16,
    height: 16,
  },
  opacity4: {
    opacity: 0.4,
  },
  opacity7: {
    opacity: 0.7,
  },
})

export const UploadedByTextIcon: React.FC<TextIconProps> = ({ value, iconProps, ...props }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <TextIconContainer
      text={t("text_icons.case_info.uploaded_by", {
        username: value,
      })}
      {...props}
    >
      <UserIcon className={cls(classes.iconSize, classes.opacity4)} {...iconProps} />
    </TextIconContainer>
  )
}

export const DBATextIcon: React.FC<TextIconProps> = ({ value, iconProps, ...props }) => {
  const classes = useStyles()

  return (
    <TextIconContainer text={value} {...props}>
      <DbaIcon className={cls(classes.iconSize, classes.opacity4)} {...iconProps} />
    </TextIconContainer>
  )
}

export const PhoneTextIcon: React.FC<TextIconProps> = ({ value, iconProps, ...props }) => {
  const classes = useStyles()

  return (
    <TextIconContainer text={value} {...props}>
      <PhoneIcon className={cls(classes.iconSize, classes.opacity4)} {...iconProps} />
    </TextIconContainer>
  )
}

export const BatchNameTextIcon: React.FC<TextIconProps> = ({ value, iconProps, ...props }) => {
  const classes = useStyles()
  return (
    <TextIconContainer text={value!} {...props}>
      <ListIcon className={cls(classes.iconSize, classes.opacity4)} {...iconProps} />
    </TextIconContainer>
  )
}

export const InsightsTextIcon: React.FC<TextIconProps> = ({ value, iconProps, ...props }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <TextIconContainer
      text={t("text_icons.case_info.insights", {
        total: value,
      })}
      {...props}
    >
      <WandIcon className={cls(classes.iconSize, classes.opacity4)} {...iconProps} />
    </TextIconContainer>
  )
}

export const PolicyIdTextIcon: React.FC<TextIconProps> = ({ value, iconProps, ...props }) => {
  const classes = useStyles(props)
  return (
    <TextIconContainer text={value} {...props}>
      <IdIcon className={cls(classes.iconSize, classes.opacity7)} {...iconProps} />
    </TextIconContainer>
  )
}

export const AddressTextIcon: React.FC<TextIconProps> = ({ value, iconProps, ...props }) => {
  const classes = useStyles(props)
  return (
    <TextIconContainer text={value} {...props}>
      <LocationPinIcon className={cls(classes.iconSize, classes.opacity4)} {...iconProps} />
    </TextIconContainer>
  )
}

export const CaseIdTextIcon: React.FC<TextIconProps> = ({ value, iconProps, ...props }) => {
  const classes = useStyles(props)
  return (
    <TextIconContainer text={value} {...props}>
      <PlanckIdIcon className={cls(classes.iconSize, classes.opacity7)} {...iconProps} />
    </TextIconContainer>
  )
}

export const RiskLevelTextIcon: React.FC<
  Omit<TextIconProps, "value"> & {
    value: CaseRun["risk_level"]
    variant?: BusinessInsightsRiskIndicatorProps["variant"]
  }
> = ({ value, variant = "small", ...props }) => {
  const { t } = useTranslation()

  return (
    <TextIconContainer
      text={t("text_icons.case_info.risk_level", {
        riskLevel: value ?? "none",
      })}
      {...props}
    >
      <BusinessInsightsRiskIndicator variant={variant} value={value} />
    </TextIconContainer>
  )
}
