import { createMap } from "@planckdata/typescript-utils"
import React from "react"
import {
  SingleBusinessPageContext,
  SingleBusinessPageUIContext,
  useSingleBusinessPage,
  useSingleBusinessPageUI,
} from "../shared/SingleBusinessPage.hooks"
import { CaseDataProvider } from "./CaseDataContext"

export const SBPOnboardingSteps = {
  tour: "tour",
  answerCodes: "answerCodes",
} as const

export type SBPOnboardingStep = typeof SBPOnboardingSteps[keyof typeof SBPOnboardingSteps]

export interface SBPOnboardingContextValue {
  step?: SBPOnboardingStep
  markStepComplete(step: SBPOnboardingStep): void
}

export const SBPOnboardingContext = React.createContext<SBPOnboardingContextValue>({
  markStepComplete: () => void 0,
})

export function useSBPOnboardingContext(): SBPOnboardingContextValue {
  const context = React.useContext(SBPOnboardingContext)

  return context
}

export const SBPContextProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const context = useSingleBusinessPage({
    sections: ["case", "geo", "main_photos", "risk_factors"],
  })
  const ui = useSingleBusinessPageUI()

  return (
    <>
      <SingleBusinessPageUIContext.Provider value={ui}>
        <SingleBusinessPageContext.Provider value={context}>
          <CaseDataProvider
            value={context.data}
            insights={context.data?.case?.insights ? createMap(context.data?.case?.insights, "name") : undefined}
            update={context.updateCaseData}
          >
            {children}
          </CaseDataProvider>
        </SingleBusinessPageContext.Provider>
      </SingleBusinessPageUIContext.Provider>
    </>
  )
}
