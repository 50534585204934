import React from "react"
import { makeStyles, SparkIcon, Theme } from "@planckdata/react-components"

export interface ShinyIconProps {
  shine?: boolean
  icon: React.ReactNode
}

// TODO: Move to react-components

const useStyles = makeStyles<Theme, ShinyIconProps>(() => ({
  shiny: {
    background: "linear-gradient(90deg, #0000 33%,rgba(255,255,255,1) 50%,#0000 66%)",
    backgroundSize: "800% 100%",
    animation: "$shine 3s infinite",

    position: "absolute",
    width: "50px",
    height: "100%",
    top: 0,
    left: 0,
  },
  "@keyframes shine": {
    "0%": {
      backgroundPosition: "right",
    },
  },
  sparkOverlay: {
    position: "absolute",
    top: "0px",
    left: "30px",
  },
}))

export const ShinyIcon: React.FC<ShinyIconProps> = (props) => {
  const { shine, icon } = props
  const classes = useStyles(props)

  return (
    <div>
      {/* <div className={`${shine ? classes.shiny : ""}`} /> */}
      {shine && <SparkIcon className={classes.sparkOverlay} />}
      {icon}
    </div>
  )
}

export default ShinyIcon
