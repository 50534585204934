import React from "react"
import { CustomComponentProps, Grid, GridProps } from "@planckdata/react-components"
import { Evidence, Feedback } from "@planckdata/api"
import Moment from "moment"
import { FeedbackChatItem } from "./FeedbackChatItem"
import { useTranslation } from "i18n"
import useHideUsername from "hooks/hide-username"

export interface FeedbackChatProps extends CustomComponentProps<GridProps> {
  feedback?: Feedback
  evidence?: Evidence | null
}

export const FeedbackChat: React.FC<FeedbackChatProps> = ({ feedback, evidence }) => {
  const { t } = useTranslation()
  const dateFormat = t("general.date_format")
  const hideUsername = useHideUsername(feedback?.created_by ?? "")
  const hideEmail = feedback?.created_by && hideUsername

  return (
    <Grid container spacing={2}>
      <Grid item style={{ width: "100%" }}>
        <FeedbackChatItem
          createdBy={hideEmail ? null : feedback?.created_by}
          createdAt={Moment(feedback?.created_date).format(dateFormat)}
          text={feedback?.comment}
          type="feedback"
          data-testid="FeedbackMessage"
        />
      </Grid>
      <Grid item hidden={!evidence} style={{ width: "100%" }}>
        <FeedbackChatItem
          createdBy={evidence?.created_by}
          createdAt={Moment(evidence?.created_date).format(dateFormat)}
          text={evidence?.text}
          type="evidence"
          data-testid="EvidenceMessage"
        />
      </Grid>
    </Grid>
  )
}
