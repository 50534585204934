import React from "react"
import { Button, CustomComponentProps } from "@planckdata/react-components"
import Image from "./images/error-429.svg"
import { CustomErrorDialog, CustomErrorDialogProps, CustomErrorPage, CustomErrorPageProps } from "./CustomError"
import { useTranslation } from "../../../i18n"

export interface Error429PageProps extends CustomComponentProps<Partial<CustomErrorPageProps>> {
  //
}

export interface Error429DialogProps extends CustomComponentProps<Partial<Omit<CustomErrorDialogProps, "open">>> {
  open: boolean
}

/** @see {@link CustomErrorPage} for props you may override */
export const Error429Page: React.FC<Error429PageProps> = (props) => {
  const { className, ...rest } = props
  const { t } = useTranslation()

  return (
    <CustomErrorPage
      className={className}
      title="error_pages.429.title"
      description="error_pages.429.description"
      image={Image}
      cta={({ ctx }) => (
        <Button
          onClick={() => {
            ctx.clearError()
            window.location.reload()
          }}
        >
          {t("error_pages.429.cta")}
        </Button>
      )}
      {...rest}
    />
  )
}

/** @see {@link CustomErrorDialog} for props you may override */
export const Error429Dialog: React.FC<Error429DialogProps> = (props) => {
  const { className, ...rest } = props
  const { t } = useTranslation()

  return (
    <CustomErrorDialog
      className={className}
      title="error_pages.429.title"
      description="error_pages.429.description"
      cta={({ ctx }) => (
        <Button
          onClick={() => {
            ctx.clearError()
            window.location.reload()
          }}
        >
          {t("error_pages.429.cta")}
        </Button>
      )}
      {...rest}
    />
  )
}
