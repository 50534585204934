import React from "react"
import { RiskLevel } from "@planckdata/api"
import { alpha, FlagIcon, makeStyles, Theme } from "@planckdata/react-components"
import { businessRiskLevelBorderColor, getRiskLevelColor } from "utils"
import clsx from "clsx"

export interface RiskFactorFlagProps {
  value?: RiskLevel | null
  variant: "small" | "large" | "large-outlined"
}

const useStyles = makeStyles<Theme, RiskFactorFlagProps>({
  icon: {
    color: ({ value }) => getRiskLevelColor(value),
  },
  iconContainer: {
    background: ({ value, variant }) =>
      variant === "large-outlined" ? undefined : alpha(getRiskLevelColor(value), 0.1),
  },
  iconContainerOutlined: {
    border: ({ value }) => `1px solid ${businessRiskLevelBorderColor(value)}`,
    background: "transparent",
  },
})

const RiskFactorFlag: React.FC<RiskFactorFlagProps> = ({ ...props }) => {
  const classes = useStyles(props)
  const { variant } = props

  return (
    <FlagIcon
      className={classes.icon}
      containerProps={{
        className: clsx(classes.iconContainer, {
          [classes.iconContainerOutlined]: variant === "large-outlined",
        }),
        size: 24,
      }}
      size={24}
    />
  )
}

export default RiskFactorFlag
