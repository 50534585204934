import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { TextField, TextFieldProps } from "@planckdata/react-components"
import { ClearValueButton } from "./buttons"
import { useStyles } from "./Styles.hook"
import { useTranslation } from "../../../i18n"
import { VALUE_ESCAPE } from "keycode-js"

export interface TextHoverEditProps extends Omit<TextFieldProps, "value" | "onChange"> {
  value: string
  onChange: (value: string) => void
}

export const TextHoverEdit: React.FC<TextHoverEditProps> = ({ value, onChange, ...props }) => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const [hover, setHover] = useState(false)
  const [focused, setFocused] = useState(false)
  const [editedValue, setEditedValue] = useState(value)
  const [cleared, setCleared] = useState(false)

  useEffect(() => {
    setEditedValue(value)
    if (!value.trim()) {
      setCleared(true)
    }
  }, [value])

  function handleBlur(newValue: string) {
    if (newValue !== value) {
      setEditedValue(newValue)
      onChange(newValue)
    }
    setFocused(false)
  }

  function handleChange(newValue: string) {
    setEditedValue(newValue)
    setCleared(false)
  }

  function handleClearItem() {
    setEditedValue("")
    onChange("")
  }

  function handleKeyUp(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === VALUE_ESCAPE) {
      setFocused(false)
      setHover(false)
      setEditedValue(value)
    }
  }

  return (
    <div className={classes.row} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <TextField
        fullWidth
        {...props}
        placeholder={cleared ? `(${t("single_business_page.insights.value_removed")})` : undefined}
        value={editedValue}
        className={clsx(classes.root, classes.text, {
          [classes.hover]: hover,
          [classes.focused]: focused,
        })}
        onFocus={() => setFocused(true)}
        onBlur={(e) => handleBlur(e.target.value)}
        onChange={(e) => handleChange(e.target.value)}
        onKeyUp={handleKeyUp}
        variant="standard"
        InputProps={{
          disableUnderline: true,
        }}
      />
      <div>
        <ClearValueButton onClick={() => handleClearItem()} visible={hover || focused} />
      </div>
    </div>
  )
}
