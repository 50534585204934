import React, { useEffect, useRef, useState } from "react"
import clsx from "clsx"
import { Select, MenuItem, SelectProps } from "@planckdata/react-components"
import { useStyles } from "./Styles.hook"
import { isEmptyInsightValue } from "utils"

export interface EnumHoverEditProps extends Omit<SelectProps, "value" | "onChange"> {
  value: string
  options: Array<string | { label: string; value: string }>
  onChange: (value: string) => void
  onInvalidValue?: (value: string) => void
}

export const EnumHoverEdit: React.FC<EnumHoverEditProps> = ({ value, options, onChange, onInvalidValue, ...props }) => {
  const classes = useStyles(props)
  const [hover, setHover] = useState(false)
  const [focused, setFocused] = useState(false)
  const [selectedValue, setSelectedValue] = useState(value)
  const isFirstRender = useRef(true)
  const items = options.map((v) => {
    const value = typeof v === "string" ? v : v.value
    const label = typeof v === "string" ? v : v.label
    return (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    )
  })
  const caret = hover ? undefined : React.createElement("span").type
  const valueNotInPossible = !options.includes(value)

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  function handleChange(newValue: string) {
    setSelectedValue(newValue)
    onChange(newValue)
    setFocused(false)
  }

  if (valueNotInPossible) {
    if (isFirstRender.current && !isEmptyInsightValue(value)) {
      onInvalidValue?.(value)
    }
    isFirstRender.current = false
    items.unshift(
      <MenuItem value={value} key={value} disabled>
        {value}
      </MenuItem>,
    )
  }

  return (
    <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} unselectable="on">
      <Select
        fullWidth
        {...props}
        value={selectedValue}
        className={clsx(classes.root, classes.select, {
          [classes.hover]: hover,
          [classes.focused]: focused,
        })}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={(e) => handleChange(e.target.value as string)}
        variant="standard"
        IconComponent={caret}
      >
        {items}
      </Select>
    </div>
  )
}
