/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CaseData } from "@planckdata/api"
import { AnalyticsAction, useTracking } from "@planckdata/react-components"
import capitalize from "lodash/capitalize"
import React from "react"
import { Options } from "react-tracking"
import { SortOrder } from "utils"
import { AnswerCodeNames } from "utils/AnswerCodeNames"

/**
 * Analytics Events
 */
export default function useTrackEvents(
  trackingData?: Partial<AnalyticsAction> | undefined,
  options?: Partial<Options<AnalyticsAction>> | undefined,
) {
  const { trackEvent, Track } = useTracking(trackingData, options)

  // TODO useState -> useMemo
  // TODO more encapsulation for the events
  const [cls] = React.useState(() => {
    /**
     * Analytics Events
     */
    return new (class TrackEvents {
      Track = Track

      /** Street View  events */
      streetView = {
        /**
         * Event Name: `Successful street view`
         */
        imageLoadSuccess: () => {
          trackEvent({ action: "Successful street view" })
        },

        /**
         * Event Name: `Failed street view`
         * */
        imageLoadFailed: () => {
          trackEvent({ action: "Failed street view" })
        },
      }

      /** Answer Code Warning events */
      answerCodeWarning = {
        /** Resubmit button/link clicked */
        trackResubmitClick(
          code: AnswerCodeNames,
          type: "button" | "text",
          clickedFrom: AnswerCodeWarningClickedFrom,
        ): void {
          trackEvent({
            action: "Answer Code Warning - Resubmit clicked",
            "Answer Code": code,
            "Clicked on": type,
            "Clicked from": clickedFrom,
          })
        },

        /** Contact Support clicked */
        trackContactSupportClick(code: AnswerCodeNames, clickedFrom: AnswerCodeWarningClickedFrom): void {
          trackEvent({
            action: "Answer Code Warning - Contact Support clicked",
            "Answer Code": code,
            "Clicked from": clickedFrom,
          })
        },

        /** See Results click */
        trackSeeResultsClick(code: AnswerCodeNames): void {
          trackEvent({
            action: "Answer Code Warning - See Results clicked",
            "Answer Code": code,
            "Clicked on": "button",
            "Clicked from": "popup message",
          })
        },

        /** Close Pop-up */
        trackClosePopup: (code: AnswerCodeNames, dontShowAgain: boolean): void => {
          trackEvent({
            action: "Answer Code Warning - Close Pop-up",
            "Answer Code": code,
            "Clicked from": "popup message",
            "Don't Show Again": booleanYesNo(dontShowAgain),
          })
        },

        /** Info collapse change */
        trackInfoCollapseChange(code: AnswerCodeNames, collapsed: boolean): void {
          trackEvent({
            action: "Answer Code Warning - Info collapse change",
            "Answer Code": code,
            "Collapse/Expand": collapsed ? "Collapse" : "Expand",
            "Clicked from": "in-page message",
          })
        },

        /** Map additional insights clicked */
        trackMapAdditionalInsightsClick(
          code: AnswerCodeNames,
          type: "button" | "text",
          clickedFrom: AnswerCodeWarningClickedFrom,
        ): void {
          trackEvent({
            action: "Answer Code Warning - Map additional insights clicked",
            "Answer Code": code,
            "Clicked on": type,
            "Clicked from": clickedFrom,
          })
        },
      }

      sbpNaics = {
        /** Edit button clicked */
        trackEditButtonClicked: () => {
          trackEvent({ action: "SBP NAICS - Edit Button Clicked" })
        },
        /** Save button clicked */
        trackSaveButtonClicked: () => {
          trackEvent({ action: "SBP NAICS - Save Button Clicked" })
        },
        /** Cancel button clicked */
        trackCancelButtonClicked: () => {
          trackEvent({ action: "SBP NAICS - Cancel Button Clicked" })
        },
        /** Primary NAICS suggestion by Planck confirmed */
        trackPrimaryNaicsConfirmed: (editMode: boolean, code: string, confidence: number | null) => {
          trackEvent({
            action: "SBP NAICS - Primary NAICS Confirmed",
            Code: code,
            Confidence: confidence,
            "Edit Mode": editMode,
          })
        },
        /** Non-suggest NAICS code was set as primary */
        trackPrimaryNaicsChanged: (code: string, confidence: number | null) => {
          trackEvent({
            action: "SBP NAICS - Primary NAICS Changed",
            Code: code,
            Confidence: confidence,
            "Edit Mode": true,
          })
        },
        /** Reset button clicked */
        trackResetButtonClicked: (editMode: boolean) => {
          trackEvent({
            action: "SBP NAICS - Reset Button Clicked",
            "Edit Mode": editMode,
          })
        },
        /** NAICS code updated */
        trackNaicsCodeUpdated: (oldCode: string, code: string, oldConfidence: number | null) => {
          trackEvent({
            action: "SBP NAICS - NAICS Code Updated",
            Code: code,
            "Old Code": oldCode,
            "New Code": code,
            "Previous Code Confidence": oldConfidence,
            "Edit Mode": true,
          })
        },
        /** NAICS code removed */
        trackNaicsCodeRemoved: (code: string, confidence: number | null) => {
          trackEvent({
            action: "SBP NAICS - NAICS Code Removed",
            Code: code,
            Confidence: confidence,
            "Edit Mode": true,
          })
        },
      }

      /** Event Name: `Download Exported File` */
      trackDownloadExportedFile(filename: string): void {
        trackEvent({ action: "Download Exported File", "File Name": filename })
      }

      /** Event Name: `Case creation` */
      trackCaseCreationSucceeded(): void {
        trackEvent({ action: "Case creation" })
      }

      /** Event Name: `Error case` */
      trackCaseCreationFailed(error: any): void {
        trackEvent({ action: "Error case", reason: error?.toString() })
      }

      /** Event Name: `Errors file download` */
      trackBatchUploadErrorsFileDownload(totalCases: number, validCases: number): void {
        trackEvent({
          action: "Errors file download",
          "Total Cases": totalCases,
          "Valid Cases": validCases,
          "Invalid Cases": totalCases - validCases,
        })
      }

      /** Event Names: `Insight Mapped`, `Insight Unmapped`, `Insight Starred`, `Insight Unstarred` */
      trackInsightMapped(
        packageName: string | null,
        insightName: string,
        action: "map" | "star",
        value: boolean,
      ): void {
        trackEvent({
          action: "Insight " + capitalize((value ? "" : "un") + (action === "star" ? "starred" : "mapped")),
          package: packageName?.replace(/\s/g, ""),
          insight_name: insightName.replace(/\s/g, ""),
        })
      }

      /** Event Name: `Insights Mapping Exported` */
      trackInsightsMappingExported(mappingColumn: boolean, packageName: string | null): void {
        trackEvent({
          action: "Insights Mapping Exported",
          mappingStatus: mappingColumn ? "Include" : "Exclude",
          package: packageName,
        })
      }

      /** Event Names: `Include Insights Mapping Status`, `Exclude Insights Mapping Status` */
      trackToggleMappingStatus(value: boolean): void {
        trackEvent({ action: `${value ? "Include" : "Exclude"} Insights Mapping Status` })
      }

      /**
       * Event Name: `Walkthrough step`
       * @deprecated
       */
      trackWalkthroughStep(step: number): void {
        trackEvent({ action: "Walkthrough step", step })
      }

      /** Event Name: `Export Button Clicked` */
      trackExportButtonClicked(): void {
        trackEvent({ action: "Export Button Clicked" })
      }

      /** Event Name: `Page View` */
      trackPageView(): void {
        trackEvent({ action: "Page View" })
      }

      /** Event Name: `Filter` */
      trackDashboardFiltersChanged(filters: Array<any>): void {
        trackEvent({ action: "Filter", filters })
      }

      /** Event Name: `Export Businesses` */
      trackExportBusinesses(
        businessNameFilter?: string,
        filters?: Array<string>,
        numOfBusinesses?: number,
        sortByField?: string,
        sortOrder?: SortOrder,
      ): void {
        trackEvent({
          action: "Export Businesses",
          "Business Name": businessNameFilter,
          "No. of Businesses": numOfBusinesses,
          filters,
          sortBy: sortByField,
          sortOrder,
        })
      }

      /** Event Name: `My businesses error` */
      trackMyBusinessesLoadFailed(): void {
        trackEvent({ action: "My businesses error" })
      }

      /** Event Name: `Search Name` */
      trackMyBusinessesSearchByName(businessName: string): void {
        trackEvent({ action: "Search Name", searchQuery: businessName })
      }

      /** Event Name: `Sort` */
      trackMyBusinessesSort(sortByField: string, sortOrder: SortOrder): void {
        trackEvent({ action: "Sort", sortBy: sortByField, sortOrder })
      }

      /** Event Name: `Filter` */
      trackMyBusinessesFiltersApplied(filters: Array<string>): void {
        trackEvent({ action: "Filter", "Selected Filters": filters })
      }

      /** Event Name: `SBP Toggle V1` */
      trackSwitchToSBPv1(): void {
        trackEvent({ action: "SBP Toggle V1" })
      }

      /** Event Name: `SBP Toggle V2.0` */
      trackSwitchToSBPv2(): void {
        trackEvent({ action: "SBP Toggle V2.0" })
      }

      /** Event Name: `V2 corner Popup - Dismiss` */
      trackSBPv2CornerPopupDismiss(timeElapsed: number): void {
        trackEvent({ action: "V2 corner Popup - Dismiss", timeElapsed })
      }

      /** Event Name: `V2 corner Popup - Try new version` */
      trackSBPv2CornerPopupConfirm(timeElapsed: number): void {
        trackEvent({ action: "V2 corner Popup - Try new version", timeElapsed })
      }

      /** Event Name: `V2 Popup - Dismiss` */
      trackSwitchToSBPv2PopupDismiss(timeElapsed: number): void {
        trackEvent({ action: "V2 Popup - Dismiss", timeElapsed })
      }

      /** Event Name: `V2 Popup - Try new version` */
      trackSwitchToSBPv2PopupConfirm(timeElapsed: number): void {
        trackEvent({ action: "V2 Popup - Try new version", timeElapsed })
      }

      /** Event Name: `New feedback` */
      trackNewFeedback(insightName: string, feedbackType: "Agree" | "Disagree", feedbackText?: string): void {
        trackEvent({
          action: "New feedback",
          "Insight name": insightName,
          "Agree/Disagree": feedbackType,
          "Feedback Text": feedbackText,
        })
      }

      /** Event Name: `Update feedback` */
      trackUpdateFeedback(insightName: string, feedbackType: "Agree" | "Disagree", feedbackText?: string): void {
        trackEvent({
          action: "Update feedback",
          "Insight name": insightName,
          "Agree/Disagree": feedbackType,
          "Feedback Text": feedbackText,
        })
      }

      /** Event Name: `Export Single` */
      trackExportSingleBusiness(caseId: string, format: string, hideEmptyValues?: boolean): void {
        trackEvent({
          action: "Export Single",
          "Case Id": caseId,
          "Export Type": format,
          "Hide Empty Values": hideEmptyValues,
        })
      }

      /** Event Name: `Sort` */
      trackSortSingleBusinessInsights(sortBy: string, sortOrder?: "asc" | "desc"): void {
        trackEvent({ action: "Sort", sortBy, sortOrder })
      }

      /** Event Name: `SAGA Links` */
      trackSagaLinksToggle(insightName: string, action: "Open" | "Close"): void {
        trackEvent({
          action: "SAGA Links",
          insight_name: insightName,
          openClose: action,
        })
      }

      /** Event Name: `Copy` */
      trackCopyToClipboard(term?: string): void {
        trackEvent({ action: "Copy", Term: term })
      }

      /** Event Name: `Search opened` */
      trackBrowserSearchOpened(): void {
        trackEvent({ action: "Search opened" })
      }

      /** Event Name: `Main Website clicked` */
      trackMainWebsiteClicked(): void {
        trackEvent({ action: "Main Website clicked" })
      }

      /** Event Name: `No Web Presence` */
      trackNoWebPresence(): void {
        trackEvent({ action: "No Web Presence" })
      }

      /** Event Name: `Confidence Clicked` */
      trackInsightConfidenceClicked(
        insightName: string,
        insightValue: string,
        insightType: string,
        confidenceValue?: number | null,
      ): void {
        trackEvent({
          action: "Confidence Clicked",
          insightName,
          insightValue,
          insightType,
          confidence: confidenceValue ?? "Not Available",
        })
      }

      /** Event Name: `Confidence Hovered` */
      trackInsightConfidenceHovered(
        insightName: string,
        insightValue: string,
        insightType: string,
        confidenceValue?: number | null,
      ): void {
        trackEvent({
          action: "Confidence Hovered",
          insightName,
          insightValue,
          insightType,
          confidence: confidenceValue ?? "Not Available",
        })
      }

      /** Event Name: `Search` */
      trackInsightSearchByTerm(term: string): void {
        trackEvent({
          action: "Search",
          value: term,
        })
      }

      /** Event Name: `Filters Changed` */
      trackInsightsFiltersChanged(filters: { [key: string]: any }): void {
        trackEvent({ action: "Filters Changed", filters })
      }

      /** Event Name: `Edit` */
      trackInsightValueEdited(
        insightName: string,
        originalValue: string,
        previousValue: string,
        newValue: string,
      ): void {
        trackEvent({
          action: "Edit",
          insightName,
          originalValue,
          previousValue,
          newValue,
        })
      }

      /** Event Name: `Restore` */
      trackInsightValueRestored(
        insightName: string,
        originalValue: string,
        previousValue: string,
        newValue: string,
      ): void {
        trackEvent({
          action: "Restore",
          insightName,
          originalValue,
          previousValue,
          newValue,
        })
      }

      /** Event Name: `Confidence Tooltip Opened` */
      trackConfidenceTooltipOpened(): void {
        trackEvent({ action: "Confidence Tooltip Opened" })
      }

      /** Event Name: `Confidence Walkthrough Closed` */
      trackConfidenceWalkthroughClosed(): void {
        trackEvent({ action: "Confidence Walkthrough Closed" })
      }

      /** Event Name: `Confidence Walkthrough Opened` */
      trackConfidenceWalkthroughOpened(): void {
        trackEvent({ action: "Confidence Walkthrough Opened" })
      }

      /** Event Name: `Tab selected` */
      trackTabSelected(tab: string): void {
        trackEvent({ action: "tab Selected", tab })
      }

      /** Event Name: `Contact support` */
      trackContactSupportClicked(): void {
        trackEvent({ action: "Contact support" })
      }

      /** Event Name: `Click on map` */
      trackMapClicked(): void {
        trackEvent({ action: "Click on map" })
      }

      /** Event Name: `SAGA Source Clicked` */
      trackSagaSourceClicked(sourceName: string): void {
        trackEvent({
          action: "SAGA Source Clicked",
          source_name: sourceName,
        })
      }

      /** Event Name: `Contact support` */
      trackContactFormSubmitted(): void {
        trackEvent({ action: "Contact support" })
      }

      termsAndConditions = {
        /** Event Name: `T&C - Click Continue` */
        termsAndConditionsAgree: () => {
          trackEvent({ action: "T&C - Click Continue" })
        },

        /** Event Name: `T&C - Click Back` */
        termsAndConditionsDisagree: () => {
          trackEvent({ action: "T&C - Click Back" })
        },
      }

      /** Event Name: `Batch upload` */
      trackBatchUploadSuccess(totalCases: number, invalidCases: number): void {
        trackEvent({
          action: "Batch upload",
          "Total Cases": totalCases,
          "Valid Cases": totalCases - invalidCases,
          "Invalid Cases": invalidCases,
        })
      }

      /** Event Name: `Batch upload error` */
      trackBatchUploadError(error: any): void {
        trackEvent({ action: "Batch upload error", Reason: error?.toString() })
      }

      /** Event Name: `Reset password form submitted` */
      trackResetPasswordSubmitted(flowId: string) {
        trackEvent({ action: "Reset password form submitted", flowId })
      }

      /** Event Name: `Reset password failed` */
      trackResetPasswordFailed(flowId: string) {
        trackEvent({ action: "Reset password failed", flowId })
      }

      /** Event Name: `Update password form submitted` */
      trackUpdatePasswordSubmit() {
        trackEvent({ action: "Update password form submitted" })
      }

      /** Event Name: `Advanced search` */
      trackAdvancedSearch(): void {
        trackEvent({ action: "Advanced search" })
      }

      /** Event Name: `Advanced search run` */
      trackAdvancedSearchRun(data: Record<"dba" | "phone" | "website", string>, existingCase?: CaseData): void {
        trackEvent({
          action: "Advanced search run",
          "Phone Field": data.phone,
          "Website Field": data.website,
          "DBA Field": data.dba,
          "Existing Case Id": existingCase?.case_id,
          "Existing Case Name": existingCase?.user_input.name,
          "Existing Case Address": existingCase?.user_input.address.description,
        })
      }

      /** Event Name: `Advanced search close` */
      trackAdvancedSearchClose(data: Record<"dba" | "phone" | "website", string>, existingCase?: CaseData): void {
        trackEvent({
          action: "Advanced search close",
          "Phone Field": data.phone,
          "Website Field": data.website,
          "DBA Field": data.dba,
          "Existing Case Id": existingCase?.case_id,
          "Existing Case Name": existingCase?.user_input.name,
          "Existing Case Address": existingCase?.user_input.address.description,
        })
      }

      /** Event Name: `Image expend` */
      trackImageExpend(): void {
        trackEvent({ action: "Image expend" })
      }
      /** Event Name: `Image navigation` */
      trackImageNavigation(): void {
        trackEvent({ action: "Image navigation" })
      }

      trackAdditionalInsightsGenerateClick(componentName: string) {
        trackEvent({ action: "GenAI case re-run", component: componentName })
      }

      resetPassword = {
        /** Event Name: `Reset password form submitted` */
        resetPasswordSubmitted: () => {
          trackEvent({ action: "Reset password form submitted successfully" })
        },

        /** Event Name: `Reset password failed` */
        resetPasswordFailed: () => {
          trackEvent({ action: "Reset password failed" })
        },

        /** Event Name: `Reset password try again clicked` */
        tryAgain: () => {
          trackEvent({ action: "Reset password try again clicked" })
        },

        /** Event Name: `Back to sign in` */
        backToLogin: () => {
          trackEvent({ action: "Back to sign in" })
        },

        /** Event Name: `Resend password email` */
        resendEmail: () => {
          trackEvent({ action: "Resend password email" })
        },

        /** Event Name: `Reset password submit clicked` */
        resetPasswordSubmitClicked: () => {
          trackEvent({ action: "Reset password submit clicked" })
        },
      }

      updatePassword = {
        /** Event Name: `Update password form submitted` */
        updatePasswordSubmit: () => {
          trackEvent({ action: "Update password form submitted" })
        },

        /** Event Name: `Password reset successful` */
        updatePasswordSucceeded: () => {
          trackEvent({ action: "Password reset successful" })
        },

        /** Event Name: `Update password failed` */
        updatePasswordFailed: (error: string) => {
          trackEvent({ action: "Password reset fail", error })
        },

        /** Event Name: `Update password try again clicked` */
        updatePasswordTryAgain: () => {
          trackEvent({ action: "Update password try again clicked" })
        },

        /** Event Name: `Back to sign in` */
        goToLogin: () => {
          trackEvent({ action: "Back to sign in" })
        },
      }

      /** Event Name: `Export limit message displayed` */
      trackExportLimit(limit: number, total: number) {
        trackEvent({ action: "Export limit message displayed", limit, total })
      }

      clarity = {
        consentGiven() {
          trackEvent({ action: "Clarity consent given" })
        },
        consentRejected() {
          trackEvent({ action: "Clarity consent rejected" })
        },
      }
    })()
  })

  return cls
}

type AnswerCodeWarningClickedFrom = "popup message" | "in-page message"

function booleanYesNo(value: boolean): "Yes" | "No" {
  return value ? "Yes" : "No"
}
