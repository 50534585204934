import React from "react"
import { observer } from "mobx-react-lite"
import { useUser } from "user-context"
import { CustomComponentProps, makeStyles, UserMenu, UserMenuProps } from "@planckdata/react-components"
import { I18nNSContext } from "@planckdata/i18n"
import { mixpanel } from "analytics"

export interface ProfileBoxProps extends CustomComponentProps<UserMenuProps, keyof typeof useStyles> {
  userMenuProps?: Partial<UserMenuProps>
}

const useStyles = makeStyles(() => ({
  paper: {
    //
  },
}))

// eslint-disable-next-line @typescript-eslint/ban-types
export const ProfileBox: React.FC<ProfileBoxProps> = observer((props) => {
  const { children, userMenuProps } = props
  const user = useUser()
  const isInternal = user.current?.internal === true
  const classes = useStyles(props)

  function handleLogout() {
    user.requestLogout()
    mixpanel.reset()
  }

  if (!user.current) {
    return null
  }

  return (
    <I18nNSContext.Provider value="react-components">
      <UserMenu
        data-testid="UserContentBox"
        classes={{ paper: classes.paper }}
        userName={user.current.username}
        orgName={isInternal ? user.current.orgName : undefined}
        profileMenuProps={{
          onLogout: handleLogout,
        }}
        style={{ display: "block" }}
        {...userMenuProps}
      >
        {children}
      </UserMenu>
    </I18nNSContext.Provider>
  )
})
