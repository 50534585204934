import React from "react"
import { ReactComponent as AskMaxMenuIconSvg } from "../../../assets/AskMax-menu_item.svg"
import { ElementComponentProps, makeStyles } from "@planckdata/react-components"

export interface AskMaxMenuItemProps extends ElementComponentProps<HTMLFormElement> {
  //
}

const useStyles = makeStyles({
  test: {
    color: "inherit",
    marginLeft: 1,
  },
})

export const AskMaxMenuIcon: React.FC<AskMaxMenuItemProps> = ({ ...props }) => {
  const classes = useStyles(props)

  return <AskMaxMenuIconSvg className={classes.test} />
}
