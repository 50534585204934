import React, { PropsWithChildren } from "react"
import { ElementComponentProps, makeStyles, colors, Link } from "@planckdata/react-components"
import clsx from "clsx"
import { Routes } from "routes"

const useStyles = makeStyles({
  buttonsContainer: {
    marginTop: 30,
  },
  link: {
    color: "inherit",
    fontWeight: "bold",
    "&:hover": {
      color: colors.linkHover,
      cursor: "pointer",
    },
  },
})

export const ContactUs: React.FC<PropsWithChildren<any>> = ({ children, ...props }) => {
  const classes = useStyles(props)

  return (
    <Link to={Routes.Support} className={classes.link}>
      {children}
    </Link>
  )
}

export interface DownloadTextProps extends ElementComponentProps<HTMLSpanElement> {
  onClick(): void
}

export const DownloadText: React.FC<DownloadTextProps> = ({ children, className, ...props }) => {
  const classes = useStyles()

  return (
    <span {...props} className={clsx(className, classes.link)}>
      {children}
    </span>
  )
}
