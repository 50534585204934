import { CurrentUserResponse as BaseCurrentUserResponse, UserSettings } from "@planckdata/api"
import { setupUserContext } from "@planckdata/storage"
import { dispatchTrackEvent, initAnalyticsUser } from "./analytics"

export enum Permission {
  OpenCase = "OpenCase",
  OpenBatch = "OpenBatch",
  ShowInsightsMapping = "ShowInsightsMapping",
  ChangeInsightsMapping = "ChangeInsightsMapping",
  ViewPocDashboard = "ViewPocDashboard",
  ShowInsightConfidence = "ShowInsightConfidence",
  ShowSagaEvidence = "ShowSagaEvidence",
  AskMax = "AskMax",
  ShowAllInsightsMapping = "ShowAllInsightsMapping",
}

export function isAuthorized(user: PWAUser | null, permission: Permission): boolean {
  return user != null && user.permissions.includes(permission)
}

const permissionToFlag: Partial<Record<Permission, keyof PWAUserFlags>> = {
  // [Permission.ViewPocDashboard]: "showDashboard",
  [Permission.AskMax]: "showAskMax",
  [Permission.ShowSagaEvidence]: "showSagaEvidence",
}

/**
 * Checks if user have specific permission or flag turned on.
 *
 * Use this when there's a flag that overrides a permission (e.g Permission.ShowSagaEvidence & showSagaEvidence flag)
 */
export function isAuthorizedOrHasFlag(user: PWAUser | null, permission: Permission): boolean {
  const flagName = permissionToFlag[permission]
  return isAuthorized(user, permission) || (!!flagName && user?.flags[flagName] === true)
}

export interface PWAUserFlags {
  /**
   * Only show current user's email
   *
   * Used by:
   * ALLIANZ_UK_WITH_DE, ALLIANZ_POC, ALLIANZ_UK_PROD, TOMERDEV_TEST, ALLIANZ_UK_TESTING_BASIC_INFO, SAHAR_TEST
   */
  anonymousState: boolean

  isPilot: boolean

  /**
   * Filter cases for current user by default
   *
   * Used by:
   * ALLIANZ_POC
   */
  defaultFilterByUser: boolean

  /**
   * Use Single Business Page v2
   *
   * @deprecated Not mapped but required for compatibility
   */
  newSingleBusinessPage: boolean

  /**
   * Disable Single Business Page v2 rollout popup (displayed on v1)
   *
   * Used by:
   * ALLIANZ_UK_TESTING_BASIC_INFO
   */
  hideSBPRolloutPopup: boolean

  /**
   * Hide NAICS Codes section in Single Business Page v2
   *
   * Used by:
   * ALLIANZ_POC, ALLIANZ_UK_PROD, SOMPO_CORRELATIONS_POC, ALLIANZ_UK_TESTING_BASIC_INFO, SOMPO_MARINE_PROD
   */
  hideSBPNaics: boolean

  /**
   * Hide Sources link in Single Business Page v2
   *
   * Used by:
   * ALLIANZ_POC, ALLIANZ_UK_PROD, SOMPO_CORRELATIONS_POC, SOMPO_MARINE_PROD
   */
  hideSBPGovtSources: boolean

  /**
   * Use SBPv1 Insights section in SBPv2
   *
   * Used by:
   * AMERICANNATIONAL_PROD_PROD, AN_WAVE_TEST_INTEGRATION, ALLIANZ_UK_PROD, AN_RENEWALS_POC
   */
  showInsightsOldView: boolean

  /**
   * Force Sentry to print to console
   *
   * Not mapped. Required only for debugging.
   */
  forceSentryConsole: boolean

  /**
   * Ask Max case creation timeout
   *
   * Used by:
   * ASKMAX_TEST
   */
  askMaxCaseTimeoutMs: number

  /**
   * Replace empty insight value (N/A) with translated value
   *
   * Used by:
   * ALLIANZ_POC
   */
  replaceNaText: boolean

  /**
   * Show privacy note when opening cases (Generate Insights page and Batch Upload page)
   *
   * Used by:
   * ALLIANZ_POC
   */
  showPrivacyNote: boolean

  /**
   * Show reference/see why buttons in insights list
   *
   * @deprecated Use `ShowSagaEvidence` permission
   */
  showSagaEvidence: boolean

  /**
   * Show Analytics Dashboard link in sidebar
   *
   * @deprecated Use `ViewPocDashboard` permission
   */
  showDashboard: boolean

  /**
   * Disable batch upload
   *
   * @deprecated Use `OpenBatch` permission
   */
  disableBatchUpload: boolean

  /**
   * Show Ask Max link in sidebar
   *
   * @deprecated Use `AskMax` permission
   */
  showAskMax: boolean

  /**
   * Show multiple images on single business page using InsightMainPhotos
   *
   * @deprecated Only exists to hold the feature before releasing
   */
  showMainPhotos: boolean

  /**
   * Show Eligibility + All Insights tab in SBPv2. When disabled, show no tabs.
   */
  displayEligibility: boolean

  /**
   * Enable additional insights in case creation
   */
  enableAdditionalInsights: boolean
}

export interface PWAUserSettings {
  /** Show old SBP version instead of new V2 */
  ShowSBPV1?: boolean
  /** Show the "switch to v1/v2" toggle */
  ShowSBPV1Toggle?: boolean
  /** Insights Mapping related prefs */
  InsightsMapping?: {
    /** Include the mapping status column when exporting insights mapping */
    ExportMappingColumn?: boolean
  }
  /** Whether usage of MS Clarity was consented (enabled) or not (disabled) */
  ClarityConsented?: boolean
}

export interface RiskFactor {
  name: string
  highRiskValue: string
  standardName: string
  displayName: string
}

export interface PWAUser extends Omit<CurrentUserResponse, "permissions"> {
  topInsights: string[]
  riskFactors: RiskFactor[] | null
  flags: Partial<PWAUserFlags>
  permissions: Array<Permission>
  userSettings: UserSettings<PWAUserSettings>
  isPilot: boolean
  hasMappedInsights: boolean
}

export interface CurrentUserResponse extends BaseCurrentUserResponse {
  topInsights: string[]
  riskFactors: RiskFactor[] | null
  flags: Partial<PWAUserFlags>
  hasMappedInsights: boolean
}

function mapUser(user: BaseCurrentUserResponse, response: CurrentUserResponse): PWAUser {
  const pwaUser: PWAUser = {
    ...user,
    riskFactors: response.riskFactors,
    flags: { ...response.flags },
    permissions: userPermissionToEnum(user.permissions),
    topInsights: response.topInsights,
    isPilot: !!response.flags.isPilot && !user.internal,
    hasMappedInsights: response.hasMappedInsights !== false,
  }

  return pwaUser
}

function userPermissionToEnum(permissions: Array<string>): Array<Permission> {
  return permissions.map((p) => stringToPermission(p)).filter(Boolean) as Array<Permission>
}

function stringToPermission(value: string): Permission | null {
  return Permission[value as keyof typeof Permission] || null
}

const { useUser, userStore, UserProvider, UserConsumer } = setupUserContext({
  storeConfig: { mapUser, dispatchTrackEvent, initAnalyticsUser },
})
export { useUser, userStore, UserProvider, UserConsumer }
