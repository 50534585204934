import React from "react"
import { hot } from "react-hot-loader/root"
import { BrowserRouter } from "react-router-dom"
import {
  planckTheme,
  ThemeProvider,
  track,
  initSentryLogger,
  updateSentryUser,
  LoggerInitOptions,
  LogLevel,
} from "@planckdata/react-components"
import { observer } from "mobx-react-lite"
import { PWAUser, UserProvider, useUser } from "user-context"
import { I18nNSContext } from "@planckdata/i18n"
import { dispatchTrackEvent } from "analytics"
import AppRouter from "./AppRouter"
import AppLoader from "./AppLoader"
import { useTranslation, defaultLocale } from "i18n"
import { ENV } from "../../env"
import { QueryClientProvider, QueryClient } from "@tanstack/react-query"
import { ErrorPageProvider } from "../ErrorPages"
import { getHostingEnv } from "@planckdata/typescript-utils"

const App: React.FC = track(undefined, { dispatch: dispatchTrackEvent })(
  observer(() => {
    const { i18n } = useTranslation()
    const user = useUser()

    const queryClient = React.useRef(
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            staleTime: Infinity,
          },
        },
      }),
    )

    React.useEffect(() => {
      if (user.current) {
        const initOptions: LoggerInitOptions = {
          sentryDsn: ENV.SENTRY_DSN,
          environment: getHostingEnv(undefined, true),
          level: ENV.LOG_LEVEL as LogLevel,
          appName: "PWA",
          forceConsole: user.current.internal || user.current.flags.forceSentryConsole,
          ignoreLocal: !user.current.internal,
        }
        const initialized = initSentryLogger(initOptions, user.current as PWAUser)
        if (!initialized) {
          // not initialized == already initialized so we just need to update user
          updateSentryUser(user.current)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.current])

    React.useEffect(() => {
      i18n.changeLanguage(user.current?.locale ?? defaultLocale)
    }, [i18n, user, user.current?.locale])

    return (
      <I18nNSContext.Provider value="pwa">
        <React.Suspense fallback={<AppLoader />}>
          <QueryClientProvider client={queryClient.current}>
            <BrowserRouter>
              <UserProvider>
                <ThemeProvider theme={planckTheme}>
                  <ErrorPageProvider>
                    <AppRouter />
                  </ErrorPageProvider>
                </ThemeProvider>
              </UserProvider>
            </BrowserRouter>
          </QueryClientProvider>
        </React.Suspense>
      </I18nNSContext.Provider>
    )
  }),
)

export default hot(App)
